import { useRef, useState } from 'react';
import { useInputDispatcherContext } from 'context';
import { useTranslation } from 'react-i18next';
import { hasTouch, isMobileOrTablet, isTV } from 'app/device';

export function useGamepadCheck(game, isMulti = false) {
    const { t } = useTranslation();
    const { getNumberOfGamepads } = useInputDispatcherContext();
    const [visible, setVisible] = useState(false);
    const protectedCallback = useRef();

    // gamepad is mandatory on TV, as the remote cannot reliably act as a generic keyboard
    // games without mouse/keyboard support do require a gamepad but not necessarily a physical one
    const gamepadIsMandatory =
        game.is_gamepad === 'mandatory' ||
        isTV ||
        (game.is_mouse_and_keyboard !== 'support' && !hasTouch()) ||
        (isMobileOrTablet && isMulti);

    const protect = (callback) => {
        if (
            getNumberOfGamepads() <= 0 && // No gamepads detected // And
            (gamepadIsMandatory || // Gamepad is mandatory to play // Or
                (game.is_virtual_gamepad === 'support' && hasTouch())) // Virtual gamepad is supported and the screen has touch capability
        ) {
            protectedCallback.current = callback;
            return () => setVisible(true);
        } else {
            return callback;
        }
    };

    const accepted = () => {
        if (gamepadIsMandatory && getNumberOfGamepads() <= 0) return;
        protectedCallback.current();
        setVisible(false);
    };

    const close = () => {
        setVisible(false);
    };

    const propsGamepadMessageBox = () => {
        // physical gamepad required, or virtual possible but no touch
        if (gamepadIsMandatory) {
            if (isMulti) {
                return {
                    message: t('warnings.gamepad.multiplayer'),
                    accept: t('buttons.try_again'),
                };
            } else {
                return {
                    message: t('warnings.gamepad.mandatory'),
                    message2: t('warnings.gamepad.connect'),
                    accept: t('buttons.try_again'),
                };
            }
        }
        // no physical gamepad found but virtual gamepad will be used instead
        else if (game.is_virtual_gamepad === 'support') {
            return {
                message: t('warnings.gamepad.virtual'),
                message2: t('warnings.gamepad.experience'),
                accept: t('buttons.continue'),
            };
        }
    };

    return { protect, visible, accepted, close, propsGamepadMessageBox };
}
