import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
} from 'react';
import * as InputDispatch from 'hooks/input-dispatch';
import SpatialNav from './SpatialNav';
import { useInputDispatcherContext } from '../input-dispatcher/InputDispatcherContext';

const SpatialNavContext = React.createContext(null);

export const useSpatialNavContext = () => useContext(SpatialNavContext);

export function SpatialNavProvider({ children, debugDraw, debug }) {
    const { pushLayer, popLayer } = useInputDispatcherContext();
    const spatialNavRef = useRef(
        new SpatialNav({ debug_draw: debugDraw, debug: debug })
    );
    const shouldRefocusRef = useRef(true);

    const SN = spatialNavRef.current;
    const value = useMemo(() => {
        return {
            // Add focusable to SN tree
            addFocusable: (params) => SN.addFocusable(params),
            // Update focusable in SN tree
            updateFocusable: (params) => SN.updateFocusable(params),
            // Remove focusable from SN tree
            removeFocusable: (params) => SN.removeFocusable(params),
            // Focus item
            setFocus: (params) => SN.setFocus(params),
            // Focus Item and mark need to refocus as false
            // Reset last focused elements
            resetLastFocused: () => {
                // clear the last focus information in the nav bar
                // so that next focus will recompute the default element correctly
                SN.resetLastFocused();
                shouldRefocusRef.current = true;
            },
            refocus: (params) => {
                SN.setFocus(params);
                shouldRefocusRef.current = false;
            },
            // Returns true when refocus is necessary
            shouldRefocus: () => shouldRefocusRef.current,
            // Make element focusable
            makeFocusable: (params) => SN.makeFocusable(params),
            // Enable || disable focusable
            setEnabled: (...params) => SN.setEnabled(...params),
            // Refresh tree rects
            refreshTree: (params) => SN.refreshTreeRects(params),
            // Set section default node
            setSectionDefaultNode: (...params) =>
                SN.setSectionDefaultNode(...params),
            // Get previous focused Element
            getPreviousFocusEl: () => SN.getPreviousFocusEl(),
        };
    }, [SN]);

    useEffect(() => {
        // Push a new layer
        pushLayer();

        // Pop layer
        return () => popLayer();
    }, [popLayer, pushLayer]);

    // Input handlers
    InputDispatch.useUpPress(useCallback(() => SN.moveUp(), [SN]));
    InputDispatch.useDownPress(useCallback(() => SN.moveDown(), [SN]));
    InputDispatch.useLeftPress(useCallback(() => SN.moveLeft(), [SN]));
    InputDispatch.useRightPress(useCallback(() => SN.moveRight(), [SN]));
    InputDispatch.useSelectPress(
        useCallback(() => document.activeElement.click(), [])
    );

    useEffect(() => {
        // get focused element at time of mount and refocus it on unmount
        const lastFocused = document.activeElement;
        return () => {
            if (lastFocused) lastFocused.focus();
        };
    }, []);

    return (
        <SpatialNavContext.Provider value={value}>
            {children}
        </SpatialNavContext.Provider>
    );
}
