import { useCallback } from 'react';
import * as S from './Column.style';
import { GameItem } from 'components/games';
import { Scrollable } from 'components/scroll-area';
import { useSpatialNavContext } from 'context';

export function Column({
    games,
    onColumnClicked,
    onItemClicked,
    colIndex,
    isGapOpen,
    lastFocused,
    isFarRight,
    sliceCount,
    disableAnimation,
    detailWindowRef,
}) {
    // when there is a detail window, it may have been scrolled above the nav bar, which would fool the spatial nav
    // we need to ensure that moving up from the top column item will always focus the detail window
    const { setFocus } = useSpatialNavContext();
    const focusDetailWindow = useCallback(
        () => setFocus(detailWindowRef.current),
        [detailWindowRef, setFocus]
    );
    const overrideMoveUp = (index) =>
        index === 0 && detailWindowRef ? focusDetailWindow : undefined;

    return (
        <S.Column>
            {[...games].splice(0, sliceCount).map((game, index) => (
                <Scrollable
                    key={`scroll-${index + colIndex}`}
                    isGapOpen={isGapOpen}
                    lastFocused={lastFocused}
                >
                    <GameItem
                        isGrid={true}
                        key={game.alias}
                        odd={(index + colIndex) % 2}
                        src={
                            (index + colIndex) % 2
                                ? game.assets.thumb
                                : game.assets.thumb_vertical
                        }
                        colIndex={colIndex}
                        itemIndex={index}
                        onClick={() => {
                            // optional column handler
                            onColumnClicked?.(colIndex);
                            // item handler
                            onItemClicked(game.alias);
                        }}
                        game={game}
                        animatable={true}
                        overrideMoveUp={overrideMoveUp(index)}
                        disableMoveRight={isFarRight}
                        disableAnimation={disableAnimation}
                    />
                </Scrollable>
            ))}
        </S.Column>
    );
}
