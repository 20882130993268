import { useCallback, useRef, useState } from 'react';
// for some reasons, needs a minimal bg to transition from
import initialBg from 'assets/images/wallpapers/initial_bg.jpg';
import wallpaper1 from 'assets/images/wallpapers/wallpaper_01.jpg';
import wallpaper2 from 'assets/images/wallpapers/wallpaper_02.jpg';
import wallpaper3 from 'assets/images/wallpapers/wallpaper_03.jpg';
import wallpaper4 from 'assets/images/wallpapers/wallpaper_04.jpg';
import wallpaper5 from 'assets/images/wallpapers/wallpaper_05.jpg';
import loginWallpaper from 'assets/images/wallpapers/login.jpg';
import { isTV } from 'app/device';
import { useSelector } from 'react-redux';
import { gameSessionSelector, SESSION_STATE } from 'slices';

const wallpapers = [wallpaper1, wallpaper2, wallpaper3, wallpaper4, wallpaper5];
const wallpaperInterval = 2 * 60 * 1000;

export function useWallpaper() {
    const [wallpaper, setWallpaper] = useState(initialBg);
    const intervalRef = useRef();
    const timeoutRef = useRef();
    const { sessionState } = useSelector(gameSessionSelector);

    const startWallpaperLoop = useCallback(() => {
        // Disable wallpaper cycling on TV
        if (isTV) return;

        // Delay wallpaper change
        timeoutRef.current = setTimeout(() => {
            setWallpaper(wallpaper1);
        }, 1000);

        // set random wallpaper
        intervalRef.current = setInterval(() => {
            // Do nothing when we have a running session
            if (sessionState.id === SESSION_STATE.INITIAL.id)
                setWallpaper(
                    wallpapers[Math.floor(Math.random() * wallpapers.length)]
                );
        }, wallpaperInterval);
    }, [sessionState.id]);

    const setMainWallpaper = useCallback(() => {
        // stop loop
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
        }
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
        setWallpaper(loginWallpaper);
    }, []);

    return { wallpaper, setWallpaper, setMainWallpaper, startWallpaperLoop };
}
