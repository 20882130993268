import { SharedElement } from 'components';
import {
    ProfileManagerButton,
    WelcomeMessage,
    Wrapper,
} from 'components/profile/selection-manager';
import { ProfileList } from 'components/profile/selection-manager/profile-list';
import {
    BlurBox,
    ConfirmationBox,
    DangerButton,
    IconButton,
    IconLogout,
    LogoMedium,
    RowLayout,
} from 'components/ui';
import { SpatialNavSection } from 'context';
import { If } from 'helpers';
import {
    useBackPress,
    useLogout,
    useProfileSelector,
    useShowResetPassword,
} from 'hooks';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { recoverySelector } from 'slices';
import { ResetPasswordBox } from './ResetPasswordBox';

export function ProfileSelectionScreen() {
    const { t } = useTranslation();
    const {
        username,
        profiles,
        onProfileSelected,
        setResetPasswordVisible,
        resetPasswordVisible,
    } = useProfileSelector();
    const { cancelled: resetPasswordCancelled } = useSelector(recoverySelector);
    const logout = useLogout();
    const startShowResetPassword = useShowResetPassword();

    useBackPress(
        useCallback(() => {
            if (resetPasswordVisible) {
                setResetPasswordVisible(false);
            } else if (!logout.visible) {
                logout.callbacks.showLogoutBox();
            }
        }, [
            logout.callbacks,
            logout.visible,
            resetPasswordVisible,
            setResetPasswordVisible,
        ])
    );

    return (
        <>
            <If condition={logout.visible}>
                <ConfirmationBox
                    message={t('login.logout_confirmation')}
                    onAccept={logout.callbacks.doLogout}
                    onDecline={logout.callbacks.declineLogout}
                />
            </If>
            <If condition={!resetPasswordCancelled && resetPasswordVisible}>
                <ResetPasswordBox
                    onAccept={() => {
                        setResetPasswordVisible(false);
                        startShowResetPassword();
                    }}
                    onDecline={() => {
                        setResetPasswordVisible(false);
                    }}
                />
            </If>
            <If condition={!resetPasswordVisible}>
                <RowLayout alignItems="center" justifyContent="center" fullSize>
                    <SharedElement id="box">
                        <BlurBox>
                            <Wrapper count={profiles.length}>
                                <SharedElement id="logo" zIndex={2} scale>
                                    <LogoMedium />
                                </SharedElement>
                                <WelcomeMessage>
                                    {t('profile.welcome_message', {
                                        username: username,
                                    })}
                                </WelcomeMessage>
                                <ProfileList
                                    list={profiles}
                                    listItemSelected={onProfileSelected}
                                />
                                <SpatialNavSection>
                                    <RowLayout>
                                        <IconButton
                                            style={{
                                                marginRight: '1rem',
                                                minWidth: '250px',
                                            }}
                                            icon={IconLogout}
                                            button={DangerButton}
                                            onClick={
                                                logout.callbacks.showLogoutBox
                                            }
                                        >
                                            {t('buttons.logout')}
                                        </IconButton>
                                        <ProfileManagerButton />
                                    </RowLayout>
                                </SpatialNavSection>
                            </Wrapper>
                        </BlurBox>
                    </SharedElement>
                </RowLayout>
            </If>
        </>
    );
}
