import {
    BottomButtons,
    ForgotPassword,
    LangSelection,
    LoginBoxWrapper,
    LoginButton,
    LoginInputsWrapper,
    RememberMe,
    WelcomeText,
} from 'components/login';
import {
    BlurBox,
    ColumnLayout,
    Input,
    LangIcon,
    Logo,
    RowLayout,
} from 'components/ui';
import { useLanguageSelect, useLogin } from 'hooks';
import { useTranslation } from 'react-i18next';
import { SharedElement } from 'components';
import { SpatialNavSection } from 'context';
import { If } from 'helpers';
import { isMobile } from 'app/device';
import Config from 'services/ConfigFront';

export function LoginScreen() {
    const { t } = useTranslation();
    const { lang, langSelectVisible, showLangSelect } = useLanguageSelect();
    const languages = Config.GetSupportedLanguages();
    const login = useLogin();

    return (
        <>
            <RowLayout
                alignItems="center"
                justifyContent="center"
                fullSize
                ref={login.LoginBoxRef}
            >
                <SharedElement id="box" freeze>
                    <BlurBox>
                        <LoginBoxWrapper>
                            <SharedElement id="logo" zIndex={2} scale>
                                <Logo />
                            </SharedElement>
                            <WelcomeText />
                            <LoginInputsWrapper>
                                <If condition={langSelectVisible}>
                                    <LangSelection />
                                </If>
                                <If condition={!langSelectVisible}>
                                    <If condition={languages.length > 1}>
                                        <LangIcon
                                            focusOnMount
                                            lang={lang}
                                            onClick={showLangSelect}
                                            /* for reset of focus after gamepad warning, only when 2+ languages */
                                            ref={login.wrapperToFocus}
                                        />
                                    </If>
                                    <Input
                                        focusOnMount={languages.length < 2}
                                        defaultValue={
                                            process.env.NODE_ENV ===
                                            'development'
                                                ? 'xavier'
                                                : ''
                                        }
                                        name="username"
                                        placeholder={t('login.username')}
                                        ref={(el) => {
                                            if (languages.length < 2) {
                                                /* for reset of focus after gamepad warning, only when 1 language */
                                                login.wrapperToFocus.current =
                                                    el;
                                            }
                                            login.register({ required: true })(
                                                el
                                            );
                                        }}
                                        tabIndex={1}
                                        autoCorrect="off"
                                        autoCapitalize="none"
                                    />
                                    <Input
                                        defaultValue={
                                            process.env.NODE_ENV ===
                                            'development'
                                                ? 'xavier'
                                                : ''
                                        }
                                        name="password"
                                        type="password"
                                        maxLength="50"
                                        placeholder={t('login.password')}
                                        ref={login.register({
                                            required: true,
                                            maxLength: 50,
                                        })}
                                        tabIndex={2}
                                        onKeyDown={(e) =>
                                            e.keyCode === 13
                                                ? login.onSubmit()
                                                : null
                                        }
                                    />
                                </If>
                            </LoginInputsWrapper>
                            <SpatialNavSection enterTo="default-element">
                                <ColumnLayout
                                    justifyContent="center"
                                    alignItems="center"
                                    fullWidth
                                    style={
                                        !langSelectVisible || {
                                            opacity: '0.35',
                                            filter: 'saturate(0)',
                                            pointerEvents: 'none',
                                        }
                                    }
                                >
                                    <RowLayout
                                        justifyContent="space-between"
                                        fullWidth
                                    >
                                        <RememberMe
                                            name="rememberMe"
                                            ref={login.register}
                                            defaultChecked
                                        />
                                        <ForgotPassword />
                                    </RowLayout>
                                    <LoginButton
                                        defaultElement
                                        isChecking={login.isChecking}
                                        onClick={login.onSubmit}
                                    />
                                </ColumnLayout>
                            </SpatialNavSection>
                            <If condition={isMobile}>
                                <BottomButtons
                                    wrapperToHide={login.LoginBoxRef}
                                    supportMethods={login.supportMethods}
                                    registerUrl={login.registerUrl}
                                />
                            </If>
                        </LoginBoxWrapper>
                    </BlurBox>
                </SharedElement>
            </RowLayout>
            <If condition={!isMobile}>
                <BottomButtons
                    wrapperToHide={login.LoginBoxRef}
                    supportMethods={login.supportMethods}
                    registerUrl={login.registerUrl}
                />
            </If>
        </>
    );
}
