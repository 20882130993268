import React from 'react';
import * as S from './MostSearched.style';
import { useTranslation } from 'react-i18next';
import { SpatialNavSection } from 'context';
import { assetURL, If } from 'helpers';
import { Scrollable } from 'components';

export const MostSearched = React.forwardRef(
    (
        {
            cues,
            onCueSelected,
            MostSearchedGames,
            onItemClicked,
            gridGamesCounter,
            isGapOpen,
            lastFocused,
        },
        forwardedRef
    ) => {
        const { t } = useTranslation();

        return (
            <S.Wrapper>
                <If condition={cues.length > 0}>
                    <Scrollable isGapOpen={isGapOpen} lastFocused={lastFocused}>
                        <S.MostSearchedCuesWrapper>
                            <S.MostSearchedCuesLabel>
                                {t('search.most_researched')}
                            </S.MostSearchedCuesLabel>
                            <SpatialNavSection>
                                <S.CuesWrapper ref={forwardedRef}>
                                    {cues.map((entry, index) => (
                                        <S.Cue
                                            key={`${entry}-${index}`}
                                            overrideMoveRight={() =>
                                                index !== cues.length - 1
                                            }
                                            onClick={() =>
                                                onCueSelected(entry.name)
                                            }
                                        >
                                            {entry.name}
                                        </S.Cue>
                                    ))}
                                </S.CuesWrapper>
                            </SpatialNavSection>
                        </S.MostSearchedCuesWrapper>
                    </Scrollable>
                </If>
                <SpatialNavSection>
                    <S.MostSearchedGames>
                        {MostSearchedGames.map((game, i) => {
                            return (
                                <S.GameThumbnailWrapper key={game.alias}>
                                    <Scrollable
                                        isGapOpen={isGapOpen}
                                        lastFocused={lastFocused}
                                        key={`scrollableThumbnails-${i}`}
                                    >
                                        <S.GameThumbnailItem
                                            onClick={() =>
                                                onItemClicked(game.alias)
                                            }
                                            overrideMoveRight={() =>
                                                i !==
                                                MostSearchedGames.length - 1
                                            }
                                            srcImage={
                                                game.assets.cover
                                                    ? assetURL(
                                                          game.assets.cover
                                                      )
                                                    : ''
                                            }
                                        />
                                    </Scrollable>
                                </S.GameThumbnailWrapper>
                            );
                        })}
                    </S.MostSearchedGames>
                </SpatialNavSection>
            </S.Wrapper>
        );
    }
);
