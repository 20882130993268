import 'swiper/swiper.scss';
import { ProfileListPlain } from './ProfileListPlain';
import { ProfileListSwiper } from './ProfileListSwiper';

export function ProfileList(props) {
    if (props.list.length > 3) {
        return <ProfileListSwiper {...props} />;
    }

    return <ProfileListPlain {...props} />;
}
