import { useEffect, useState } from 'react';
import { ConfigFront } from 'services';
import {
    checkMaintenance,
    globalSelector,
    MaintenanceStatus,
} from 'slices/global/GlobalSlice';
import { useDispatch, useSelector } from 'react-redux';

export function usePreLoader() {
    const [isLoading, setIsLoading] = useState(true);
    const { maintenanceStatus } = useSelector(globalSelector);
    const dispatch = useDispatch();

    useEffect(() => {
        // Check maintenance Status
        dispatch(checkMaintenance());
    }, [dispatch]);

    useEffect(() => {
        switch (maintenanceStatus) {
            case MaintenanceStatus.NORMAL:
            case MaintenanceStatus.INTERNAL_ONLY:
                loadConfigFront();
                break;
            case MaintenanceStatus.MAINTENANCE:
                setIsLoading(false);
                break;
            default:
                return;
        }
    }, [maintenanceStatus]);

    const loadConfigFront = () => {
        // the initial config alias is 'default' but can be overridden in the URL for
        // internal purposes with the config_frontt_alias parameter
        const search = new URLSearchParams(window.location?.search);
        const alias = search.get('config_front_alias') || 'default';
        Promise.all([ConfigFront.LoadInitial(alias)])
            .then(() => {
                // TODO: find a way to either reset i18n or initialize it only now
                setIsLoading(false);
            })
            .catch((err) => {
                // trick to show error boundaries while in hook, thread : https://github.com/facebook/react/issues/14981
                setIsLoading(() => {
                    throw err;
                });
            });
    };

    return { isLoading, maintenanceStatus };
}
