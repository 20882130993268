import { MessageBox } from 'components/ui';
import { useGlobalMessageBox } from 'hooks';
import { If } from 'helpers';

export function GlobalMessageBox() {
    const { message, message2, type, onClick } = useGlobalMessageBox();

    return (
        <If condition={message}>
            <MessageBox
                message={message}
                message2={message2}
                type={type}
                onClick={onClick}
            />
        </If>
    );
}
