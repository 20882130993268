import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    globalNavigationSelector,
    profileSelector,
    setCurrentSubTab,
    setCurrentTab,
    setSettingsVisible,
} from 'slices';
import history from 'app/history';
import { useSpatialNavContext, useThemeContext } from 'context';
import { useHomePress } from 'hooks';
import { isHP } from 'app/device';

export function useGlobalNavigation() {
    const { navigation, currentTab, visible, subMenuVisible, settingsVisible } =
        useSelector(globalNavigationSelector);
    const dispatch = useDispatch();
    const { setMainWallpaper, startWallpaperLoop } = useThemeContext();
    const { resetLastFocused } = useSpatialNavContext();
    const { currentProfile } = useSelector(profileSelector);

    const onTabSelected = useCallback(
        (tab) => {
            // Route to target tab
            history.push(navigation[tab].route);

            dispatch(setCurrentTab(tab));
        },
        [dispatch, navigation]
    );

    useHomePress(
        useCallback(() => {
            if (isHP && currentProfile) {
                // prevent home btn on profile selection screen (even if selection profile screen is skipped in HP)
                resetLastFocused();
                onTabSelected('categories');

                if (settingsVisible) dispatch(setSettingsVisible(false));
            }
        }, [
            currentProfile,
            dispatch,
            onTabSelected,
            resetLastFocused,
            settingsVisible,
        ])
    );

    useEffect(() => {
        if (visible) {
            startWallpaperLoop();
        } else {
            setMainWallpaper();
        }
    }, [setMainWallpaper, startWallpaperLoop, visible]);

    const onSubTabSelected = (item) => {
        dispatch(setCurrentSubTab(item));
    };

    return {
        navigation,
        currentTab,
        visible,
        subMenuVisible,
        onTabSelected,
        onSubTabSelected,
    };
}
