import { If } from 'helpers';
import { useTranslation } from 'react-i18next';
import { Games } from 'services';
import * as S from './Titles.style';

export function Titles({ colsTitles }) {
    const { t } = useTranslation();

    return (
        <S.Wrapper>
            {colsTitles.map((title, index) => (
                <S.TitleWrapper key={index}>
                    <If condition={!Games.ReducedUI()}>
                        <S.PreTitle>{t('charts.top_10')}</S.PreTitle>
                    </If>
                    <S.Title>{title}</S.Title>
                </S.TitleWrapper>
            ))}
        </S.Wrapper>
    );
}
