import {
    IconPower,
    IconSettings,
    LangIcon,
    NeutralButton,
    PrimaryButton,
} from 'components/ui';
import { useTranslation } from 'react-i18next';
import * as S from './Settings.style';
import { useAsideSettings } from 'hooks';
import { isHP, isTV } from 'app/device';
import { If } from 'helpers';
import Config from 'services/ConfigFront';

export function Settings({
    settingsButtonRef,
    onLogout,
    onQuit,
    onShowSupport,
    onShowLanguageBox,
    lang,
}) {
    const { t } = useTranslation();
    const { wrapperRef, onChangeProfile } = useAsideSettings(settingsButtonRef);
    const languages = Config.GetSupportedLanguages();

    return (
        <S.Wrapper ref={wrapperRef}>
            <S.SettingsWrapper>
                <S.SettingsIcon>
                    <IconSettings />
                </S.SettingsIcon>
                <S.SettingsText>{t('settings.settings')}</S.SettingsText>
            </S.SettingsWrapper>

            <S.Buttons>
                <If condition={!isHP}>
                    <PrimaryButton
                        disableMoveLeft
                        disableMoveRight
                        onClick={onChangeProfile}
                    >
                        {t('settings.change_profile')}
                    </PrimaryButton>
                </If>
                <If condition={languages.length > 1}>
                    <S.LangWrapper
                        disableMoveLeft
                        disableMoveRight
                        onClick={onShowLanguageBox}
                        overrideMoveUp={() => {
                            settingsButtonRef.current.focus();
                            return false;
                        }} // this has to be set for the first item of Settings. (focus settings button if going up on first item instead of favorites)
                    >
                        <LangIcon
                            isBox
                            lang={lang}
                            style={{ position: 'absolute', left: '2px' }}
                        />
                        <S.Language>
                            {/* ugly wart because backend uses zt for zh-tw */}
                            {t(`languages.${lang === 'zt' ? 'zh' : lang}`)}
                        </S.Language>
                    </S.LangWrapper>
                </If>
                <If condition={!isHP}>
                    <NeutralButton
                        disableMoveLeft
                        disableMoveRight
                        onClick={onShowSupport}
                    >
                        {t('settings.support')}
                    </NeutralButton>
                    <NeutralButton
                        onClick={onLogout}
                        disableMoveLeft
                        disableMoveRight
                        disableMoveDown={!isTV}
                    >
                        {t('settings.disconnect')}
                    </NeutralButton>
                </If>
            </S.Buttons>

            <If condition={isTV}>
                <S.QuitWrapper
                    onClick={onQuit}
                    disableMoveLeft
                    disableMoveRight
                    disableMoveDown
                >
                    <S.QuitIcon>
                        <IconPower />
                    </S.QuitIcon>
                    <S.QuitText>{t('settings.quit')}</S.QuitText>
                </S.QuitWrapper>
            </If>
        </S.Wrapper>
    );
}
