import {
    initPinCodeCheck,
    setCurrentProfile,
    setGlobalNavCategories,
    setPinCode,
} from 'slices';
import { FrontEndHelper, Games } from 'services';
import Routes from 'app/routes';
import { useDispatch } from 'react-redux';
import { useStartNavigation } from '../global-navigation';
import { useHistory } from 'react-router-dom';

export function useProfileLoader() {
    const history = useHistory();
    const dispatch = useDispatch();
    const startNavigation = useStartNavigation();

    const onProfileSelected = (profile) => {
        // Set selected profile
        dispatch(setCurrentProfile(profile));

        // Set Games Minimum age filter
        Games.Filter(FrontEndHelper.GetMinimumAge(profile.contentRating.alias));

        // Set GlobalNav Categories
        dispatch(
            setGlobalNavCategories(Games.GetCategories().map((c) => c.name))
        );

        if (profile.locked) {
            dispatch(
                initPinCodeCheck({
                    profileUID: profile.uid,
                })
            );
            history.push(Routes.PIN_CODE_CHECK);
        } else {
            // clear code potentially stored from a previous profile use
            dispatch(setPinCode(''));
            startNavigation(profile.uid);
        }
    };

    return { onProfileSelected };
}
