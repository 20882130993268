import { useDispatch, useSelector } from 'react-redux';
import {
    changeLoginLanguage,
    loginSelector,
    showLanguageSelection,
} from 'slices';
import { useEffect, useState } from 'react';
import { Games } from 'services';
import i18n from 'i18n';

export function useLanguageSelect() {
    const { lang, langSelectVisible } = useSelector(loginSelector);
    const dispatch = useDispatch();
    const [visible, setVisible] = useState(false);

    // Watch language change
    useEffect(() => {
        if (lang !== i18n.language) {
            i18n.changeLanguage(lang).then(() => {
                Games.UpdateAssets();
            });
        }
    }, [lang]);

    // Show language selector
    const showLangSelect = () => {
        dispatch(showLanguageSelection());
    };

    // Change language
    const changeLanguage = (newLanguage) => {
        dispatch(changeLoginLanguage(newLanguage));
    };

    // box display
    const showLanguageBox = () => {
        setVisible(true);
    };

    const hideLanguageBox = () => {
        setVisible(false);
    };

    return {
        lang,
        langSelectVisible,
        showLangSelect,
        changeLanguage,
        showLanguageBox,
        hideLanguageBox,
        visible,
    };
}
