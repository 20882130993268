import { DangerButton, NeutralButton, RegularButton } from 'components/ui';
import * as S from '../ActionButtons.style';
import { useTranslation } from 'react-i18next';
import { useMultiViewContext } from 'context/multi-view';
import { PlayButton, TrailerButton } from 'components/games';
import { If } from 'helpers';
import React, { useState } from 'react';

export function ActionButtons({
    game,
    onUnFavorite,
    onQuickMatch,
    playButtonRef,
}) {
    const { t } = useTranslation();
    const { setView } = useMultiViewContext();
    const [confirm, setConfirm] = useState(false);

    return (
        <S.Wrapper>
            <PlayButton
                defaultElement
                key={game.alias}
                game={game}
                playButtonRef={playButtonRef}
                disableMoveRight={true}
            />
            <If condition={!onUnFavorite}>
                <TrailerButton game={game} disableMoveRight={true} />
            </If>
            <If condition={game.hasQuickMatch}>
                <RegularButton
                    onClick={() => onQuickMatch(game.alias)}
                    disableMoveRight={true}
                >
                    {t('buttons.quick-match')}
                </RegularButton>
            </If>
            <RegularButton
                onClick={() => setView('moreView')}
                disableMoveRight={true}
            >
                {t('buttons.more')}
            </RegularButton>
            <If condition={onUnFavorite}>
                <If condition={!confirm}>
                    <NeutralButton
                        title={t('buttons.unfavorite')}
                        onClick={() => setConfirm(true)}
                    >
                        {t('buttons.unfavorite')}
                    </NeutralButton>
                </If>
                <If condition={confirm}>
                    <DangerButton
                        focusOnMount
                        onBlur={() => setConfirm(false)}
                        onClick={() => onUnFavorite(game.alias)}
                    >
                        {t('buttons.confirm')}
                    </DangerButton>
                </If>
            </If>
        </S.Wrapper>
    );
}
