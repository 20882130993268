import { useState } from 'react';
import { useCharts, useGameWindow } from 'hooks';
import { GamesWrapper, GameWindow, Grid } from 'components/games';
import { Titles, TitlesWrapper } from 'components/games/charts';
import { ColumnLayout } from 'components/ui';
import { QuitWrapper, Scrollable } from 'components';
import { If } from 'helpers';
import { isMobile } from 'app/device';

export function ChartsScreen() {
    const { colsTitles, columns } = useCharts();
    const [games, setGames] = useState([]);
    const gameWindow = useGameWindow(games);

    const onColumnClicked = (colIndex) => setGames(columns[colIndex]);

    return (
        <>
            <QuitWrapper disabled={gameWindow.visible} />
            <ColumnLayout alignItems="center" fullSize>
                <If condition={gameWindow.visible}>
                    <GameWindow
                        index={gameWindow.selectedIndex}
                        indexMax={games.length - 1}
                        defaultView={gameWindow.defaultView}
                        game={games[gameWindow.selectedIndex]}
                        onClose={gameWindow.close}
                        onPrev={gameWindow.prev}
                        onNext={gameWindow.next}
                        swipeHandlers={gameWindow.handlers}
                        ref={gameWindow.gameWindowRef}
                    />
                </If>
                <GamesWrapper minTop={isMobile ? 250 : 350}>
                    <ColumnLayout fullWidth>
                        <Scrollable
                            isGapOpen={gameWindow.gapOpen}
                            lastFocused={gameWindow.lastFocused}
                        >
                            <TitlesWrapper>
                                <Titles colsTitles={colsTitles} />
                            </TitlesWrapper>
                        </Scrollable>
                        <Grid
                            isGapOpen={gameWindow.gapOpen}
                            lastFocused={gameWindow.lastFocused}
                            onColumnClicked={onColumnClicked}
                            onItemClicked={gameWindow.open}
                            columns={columns}
                        />
                    </ColumnLayout>
                </GamesWrapper>
            </ColumnLayout>
        </>
    );
}
