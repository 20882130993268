import * as S from './MessageBoxTitle.style';
import { IconSettings } from '../../icons';
import React from 'react';

export function MessageBoxTitle({ title, Icon }) {
    return (
        <S.BoxTitleWrapper>
            <S.IconTitle>{Icon ? Icon : <IconSettings />}</S.IconTitle>
            <S.BoxTitle>{title}</S.BoxTitle>
        </S.BoxTitleWrapper>
    );
}
