import { createSlice } from '@reduxjs/toolkit';
import { makeInitialState } from './initialState';

// Slice
const globalNavigationSlice = createSlice({
    name: 'globalNavigation',

    initialState: makeInitialState(),

    reducers: {
        setCurrentTab: (state, action) => {
            if (state.currentTab.name !== action.payload) {
                state.currentTab.name = action.payload;
                state.currentTab.subTab =
                    state.navigation[state.currentTab.name].subMenu?.[0] || '';
                state.subMenuVisible = true;
            }
        },

        setCurrentSubTab: (state, action) => {
            state.currentTab.subTab = action.payload;
        },

        setSettingsVisible: (state, action) => {
            state.settingsVisible = action.payload;
        },

        toggleSettings: (state) => {
            state.settingsVisible = !state.settingsVisible;
        },

        setGlobalNavCategories: (state, action) => {
            // re-evaluate initial state according to current number of available games
            state.navigation = makeInitialState().navigation;

            // deep modifications of state
            //immer internal produce seems not working here...
            // see : https://redux-toolkit.js.org/usage/immer-reducers
            state.navigation = {
                ...state.navigation,
                categories: {
                    ...state.navigation.categories,
                    subMenu: ['all', ...action.payload],
                },
            };
        },

        setFavoritesVisible: (state, action) => {
            state.currentTab = { name: '', subTab: '' };
            state.favoritesVisible = action.payload;
        },

        setSubMenuVisible: (state, action) => {
            state.subMenuVisible = action.payload;
        },

        showGlobalNavigation: (state) => {
            state.visible = true;
        },

        hideGlobalNavigation: (state) => {
            state.visible = false;
        },

        resetGlobalNavigation: () => makeInitialState(),
    },
});

// Actions
export const {
    setCurrentTab,
    setCurrentSubTab,
    setFavoritesVisible,
    setSettingsVisible,
    hideGlobalNavigation,
    showGlobalNavigation,
    toggleSettings,
    resetGlobalNavigation,
    setGlobalNavCategories,
    setSubMenuVisible,
} = globalNavigationSlice.actions;

// Selector
export const globalNavigationSelector = (state) => state.globalNavigation;

// Reducer
export const globalNavigationReducer = globalNavigationSlice.reducer;
