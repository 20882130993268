import * as DeviceDetect from 'react-device-detect';

const buildTarget = process.env.REACT_APP_BUILD_TARGET?.trim();

// isHp Target
export const isHP = buildTarget === 'hp';
if (isHP) console.log('HOSPITALITY Build');

// isTv Target
export const isTV = buildTarget === 'tv' || isHP;
if (isTV && !isHP) console.log('TV Build');

// Stubs
export const landscape = () => {};
export const mobile = () => {};
export const mobileSmall = () => {};
export const tablet = () => {};
export const desktopSmall = () => {};
export const hover = () => {};
export const tv = () => {};

// Device / Navigator
export const isAndroid = DeviceDetect.isAndroid;
export const isIOS = DeviceDetect.isIOS;
export const isMacOs = DeviceDetect.isMacOs;
export const isSafari = DeviceDetect.isSafari;
export const isFirefox = DeviceDetect.isFirefox;
export const isMobile = DeviceDetect.isMobileOnly;
export const isMobileOrTablet = DeviceDetect.isMobile;
export const isChrome = DeviceDetect.isChrome;

// OS
export const osName = DeviceDetect.osName;
export const osVersion = DeviceDetect.osVersion;

// Device capabilities

export const isStandalone = () => {
    return window.matchMedia('(display-mode: standalone)').matches;
};

export const hasTouch = () => {
    return (
        'ontouchstart' in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0
    );
};

export const supportBackDropFilter =
    !isTV && (isSafari || CSS.supports('(backdrop-filter:blur(32px))'));

export const hasRequiredIOSVersion = (() => {
    const minIosVersion = 14;
    //check git history for previous way to check iOS version...
    //DetectDevice.osVersion seems wrong for now
    //Here we are checking the browser version because on iOS device Safari is updated along the the device software update

    return (
        isIOS &&
        isMobileOrTablet &&
        parseInt(DeviceDetect.browserVersion) >= minIosVersion
    );
})();
