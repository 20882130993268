import { isTV } from 'app/device';
import { QuitWrapper, Scrollable } from 'components';
import { GamesWrapper, GameWindow, Grid, NoGamesFound } from 'components/games';
import * as S from 'components/games/categories';
import { ColumnLayout } from 'components/ui';
import { If } from 'helpers';
import { useCategories, useGameWindow } from 'hooks';
import { useTranslation } from 'react-i18next';
import { Games } from 'services';

export function CategoriesScreen() {
    const { currentTab, columns, games } = useCategories();
    const gameWindow = useGameWindow(games);
    const { t } = useTranslation();

    return (
        <>
            <QuitWrapper disabled={gameWindow.visible} />
            <ColumnLayout alignItems="center" fullSize>
                <If condition={gameWindow.visible}>
                    <GameWindow
                        visible={gameWindow.visible}
                        index={gameWindow.selectedIndex}
                        indexMax={games.length - 1}
                        defaultView={gameWindow.defaultView}
                        game={games[gameWindow.selectedIndex]}
                        onClose={gameWindow.close}
                        onPrev={gameWindow.prev}
                        onNext={gameWindow.next}
                        swipeHandlers={gameWindow.handlers}
                        ref={gameWindow.gameWindowRef}
                    />
                </If>
                <GamesWrapper minTop={isTV ? 350 : 250}>
                    <If condition={games.length <= 0}>
                        <NoGamesFound>{t('categories.no_games')}</NoGamesFound>
                    </If>
                    <If condition={games.length > 0}>
                        <ColumnLayout fullWidth>
                            <Scrollable
                                isGapOpen={gameWindow.gapOpen}
                                lastFocused={gameWindow.lastFocused}
                            >
                                <S.CategoryTitle key={currentTab.subTab}>
                                    {Games.ReducedUI()
                                        ? ''
                                        : t(
                                              `categories.${currentTab.subTab.toLowerCase()}`
                                          )}
                                </S.CategoryTitle>
                            </Scrollable>
                            <Grid
                                isGapOpen={gameWindow.gapOpen}
                                lastFocused={gameWindow.lastFocused}
                                onItemClicked={gameWindow.open}
                                columns={columns}
                            />
                        </ColumnLayout>
                    </If>
                </GamesWrapper>
            </ColumnLayout>
        </>
    );
}
