import { isMobile } from 'app/device';
import { GameDescription, GameItem } from 'components/games';
import { RowLayout } from 'components/ui';
import { SpatialNavSection } from 'context';
import { If } from 'helpers';
import { useTriggerBottomLeftPress, useTriggerBottomRightPress } from 'hooks';
import { useRef } from 'react';
import { useSwipeable } from 'react-swipeable';
import * as View from '../View.style';
import { ActionButtons } from './action-buttons/ActionButtons';
import * as S from './DescriptionView.style';
import { NavButtons } from './nav-buttons/NavButtons';

export function DescriptionView({
    game,
    onClose,
    onNext,
    onPrev,
    index,
    indexMax,
    onUnFavorite,
}) {
    const navRef = useRef();

    const handlers = useSwipeable({
        onSwipedRight: () => onPrev(),
        onSwipedLeft: () => onNext(),
    });

    //gamepad shortcut with right/left triggers to go previous / next game
    useTriggerBottomLeftPress(onPrev);
    useTriggerBottomRightPress(onNext);

    return (
        <SpatialNavSection focusOnMount enterTo="default-element" {...handlers}>
            <RowLayout fullSize>
                <View.LeftPan>
                    <GameItem
                        game={game}
                        key={`cover-${index}`}
                        src={game.assets.cover}
                    />
                    <If condition={isMobile}>
                        <ActionButtons
                            game={game}
                            onClose={onClose}
                            onUnFavorite={onUnFavorite}
                            navRef={navRef}
                        />
                    </If>
                </View.LeftPan>

                <View.RightPan>
                    <S.Content>
                        <GameDescription game={game} />
                        <If condition={!isMobile}>
                            <ActionButtons
                                game={game}
                                onClose={onClose}
                                onUnFavorite={onUnFavorite}
                            />
                        </If>
                    </S.Content>
                    <NavButtons
                        ref={navRef}
                        onNext={onNext}
                        onPrev={onPrev}
                        onClose={onClose}
                        index={index}
                        indexMax={indexMax}
                    />
                </View.RightPan>
            </RowLayout>
        </SpatialNavSection>
    );
}
