import * as S from './LangIcon.style';
import { useTranslation } from 'react-i18next';

// Get icons from : https://www.flaticon.com/fr/packs/square-country-simple-flags
// Note : don't use SVG => border aliasing problem
import DeIcon from 'assets/images/language/de.png';
import EnIcon from 'assets/images/language/en.png';
import EsIcon from 'assets/images/language/es.png';
import FrIcon from 'assets/images/language/fr.png';
import ItIcon from 'assets/images/language/it.png';
import PtIcon from 'assets/images/language/pt.png';
import SlIcon from 'assets/images/language/sl.png';
import ZtIcon from 'assets/images/language/zt.png';
import { forwardRef } from 'react';

export const LangIcon = forwardRef(({ lang, ...props }, ref) => {
    const { t } = useTranslation();

    const icons = {
        de: DeIcon,
        en: EnIcon,
        es: EsIcon,
        fr: FrIcon,
        it: ItIcon,
        pt: PtIcon,
        sl: SlIcon,
        zt: ZtIcon,
    };

    return (
        <S.LangIcon
            {...props}
            ref={ref}
            src={icons[lang]}
            alt={t(`languages.${lang}`)}
        />
    );
});
