import { mobile, mobileSmall, tv } from 'app/device';
import Theme from 'app/theme';
import styled, { css } from 'styled-components';

export const NavButton = styled.div`
    align-self: center;
    flex-shrink: 0;
    outline: none;
    cursor: pointer;
    width: 62px;
    height: 62px;
    margin-top: -16px;
    margin-left: 25px;
    margin-right: 25px;
    pointer-events: ${(props) => (props.isDisabled ? 'none' : 'auto')};
    transition: opacity 150ms ease-out;

    ${tv(css`
        width: 114px;
        height: 114px;
        margin-top: -50px;
    `)};

    ${mobile(css`
        margin-left: 12px;
        margin-right: 12px;
    `)};

    ${mobileSmall(css`
        width: 45px;
        height: 45px;
    `)};

    svg {
        fill: ${(props) =>
            props.isDisabled ? Theme.COLORS.GRAY_L4 : Theme.COLORS.WHITE};
    }

    &:focus,
    &:hover {
        svg {
            fill: ${Theme.COLORS.PRIMARY_L2};
        }
    }

    &:active {
        svg {
            fill: ${Theme.COLORS.PRIMARY_L3};
        }
    }
`;

NavButton.defaultProps = {
    isDisabled: 0,
};
