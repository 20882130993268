import { GameItem } from 'components/games';
import { RowLayout } from 'components/ui';
import { MultiView, View } from 'context/multi-view';
import { useActionTopPress } from 'hooks';
import { useToggleFavorite } from 'hooks/games/useToggleFavorite';
import React, { useImperativeHandle, useRef } from 'react';
import { Games } from 'services';
import * as S from './GameWindowMinimized.style';
import { DescriptionView } from './views/description/DescriptionView';
import { MoreView } from './views/more/MoreView';

export const GameWindowMinimized = React.forwardRef(
    ({ game, ...props }, forwardRef) => {
        const favorite = useToggleFavorite();
        const wrapperRef = useRef();
        const playButtonRef = useRef();

        //use external and internal ref cf : https://medium.com/vimeo-engineering-blog/handling-internal-and-external-refs-to-the-same-element-with-useimperativehandle-in-react-746ff6d377fe
        useImperativeHandle(forwardRef, () => wrapperRef.current);

        useActionTopPress(() => {
            if (!Games.FavoritesEnabled()) {
                return;
            }
            //check if focus is on game window minimized
            if (wrapperRef.current.contains(document.activeElement)) {
                favorite.toggleGame(game.alias);
            }
        });

        return (
            <S.Wrapper ref={wrapperRef}>
                <S.Window>
                    <RowLayout fullSize>
                        <S.Cover>
                            <GameItem game={game} src={game.assets.cover} />
                        </S.Cover>
                        <MultiView defaultView="description">
                            <View
                                name="description"
                                component={DescriptionView}
                                playButtonRef={playButtonRef}
                                {...props}
                                game={game}
                            />
                            <View
                                name="moreView"
                                component={MoreView}
                                {...props}
                                game={game}
                            />
                        </MultiView>
                    </RowLayout>
                </S.Window>
            </S.Wrapper>
        );
    }
);
