import { GameSession } from 'components/games';
import { Eula } from 'components/games/eula/Eula';
import { ConfirmationBox, LoadingButton, PrimaryButton } from 'components/ui';
import { useSpatialNavContext } from 'context';
import { If } from 'helpers';
import { useEulaVerification, useStartSession } from 'hooks';
import { useConnectionSpeedCheck } from 'hooks/games/useConnectionSpeedCheck';
import { useGamepadCheck } from 'hooks/games/useGamepadCheck';
import { useCallback } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { globalMessageBoxSelector, SESSION_STATE } from 'slices';

export function PlayButton({ game, playButtonRef, ...props }) {
    const { t } = useTranslation();
    const eula = useEulaVerification(game);
    const gamepadCheck = useGamepadCheck(game);
    const connectionSpeedCheck = useConnectionSpeedCheck();
    const globalMessageBox = useSelector(globalMessageBoxSelector);
    const { refreshTree, setFocus } = useSpatialNavContext();

    const onSessionTerminated = useCallback(() => {
        // Check if the global message box isn't open
        if (!globalMessageBox.message && playButtonRef.current) {
            refreshTree({});
            setFocus(playButtonRef.current);
        }
    }, [globalMessageBox.message, playButtonRef, refreshTree, setFocus]);

    const { startSession, sessionState, hasSession } = useStartSession({
        game,
        onSessionTerminated,
    });

    return (
        <>
            <If condition={connectionSpeedCheck.visible}>
                <ConfirmationBox
                    {...connectionSpeedCheck.propsMessageBox()}
                    decline={t('buttons.cancel')}
                    onAccept={connectionSpeedCheck.accepted}
                    onDecline={connectionSpeedCheck.close}
                />
            </If>

            <If condition={gamepadCheck.visible}>
                <ConfirmationBox
                    {...gamepadCheck.propsGamepadMessageBox()}
                    decline={t('buttons.cancel')}
                    onAccept={gamepadCheck.accepted}
                    onDecline={gamepadCheck.close}
                />
            </If>

            <If condition={eula.visible}>
                {ReactDOM.createPortal(
                    <Eula
                        game={game}
                        readOnly={false}
                        onApproved={eula.accepted}
                        onClose={eula.close}
                    />,
                    document.body
                )}
            </If>

            <If
                condition={
                    hasSession.current &&
                    sessionState.id > SESSION_STATE.STARTING.id
                }
            >
                <GameSession />
            </If>

            <LoadingButton
                {...props}
                ref={playButtonRef}
                isLoading={hasSession.current && sessionState.id}
                button={PrimaryButton}
                loadingText={
                    sessionState.id && t(`game_session.${sessionState.label}`)
                }
                onClick={connectionSpeedCheck.protect(
                    gamepadCheck.protect(eula.protect(startSession))
                )}
            >
                {t('buttons.play')}
            </LoadingButton>
        </>
    );
}
