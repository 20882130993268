import { SpatialNavProvider, SpatialNavSection } from 'context';
import * as S from './MessageBoxWrapper.style';

export function MessageBoxWrapper({ children }) {
    return (
        <SpatialNavProvider>
            <SpatialNavSection focusOnMount enterTo="default-element">
                <S.Wrapper>
                    <S.BlurredWallpaper />
                    <S.MessageBox>{children}</S.MessageBox>
                </S.Wrapper>
            </SpatialNavSection>
        </SpatialNavProvider>
    );
}
