import { DangerButton, NeutralButton } from 'components/ui/buttons';
import * as S from 'components/ui/message-box/MessageBox.style';
import { IconWarning, RowLayout } from 'components/ui/index';
import { useTranslation } from 'react-i18next';
import { If } from 'helpers';
import { useBackPress } from 'hooks';
import { MessageBoxWrapper } from 'components/ui/message-box';
import { createPortal } from 'react-dom';

export function ConfirmationBox({
    message,
    message2,
    onAccept,
    onDecline,
    accept,
    decline,
}) {
    const { t } = useTranslation();
    useBackPress(onDecline);

    // Note: nested backdrop-filters does not work
    return createPortal(
        <MessageBoxWrapper>
            <If condition={!message2}>
                <S.MessageWrapper>
                    <S.Icon>
                        <IconWarning />
                    </S.Icon>
                    <S.Text>{message}</S.Text>
                </S.MessageWrapper>
            </If>
            <If condition={message2}>
                <S.MultipleMessagesWrapper>
                    <S.Icon>
                        <IconWarning />
                    </S.Icon>
                    <S.Text>{message}</S.Text>
                    <S.Text>{message2}</S.Text>
                </S.MultipleMessagesWrapper>
            </If>
            <RowLayout>
                <DangerButton
                    style={{ marginRight: '1rem' }}
                    onClick={onAccept}
                    bold
                >
                    {accept || t('buttons.yes')}
                </DangerButton>
                <NeutralButton onClick={onDecline} bold>
                    {decline || t('buttons.cancel')}
                </NeutralButton>
            </RowLayout>
        </MessageBoxWrapper>,
        document.body
    );
}
