import { isTV, mobile, tv } from 'app/device';
import Theme from 'app/theme';
import * as CheckboxStyle from 'components/ui/checkbox/Checkbox.style';
import { withFocusable } from 'helpers';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    border-radius: 20px;
    margin-top: 16px;
    padding: 12px;
    flex-grow: 1;

    justify-content: space-between;
    ${tv(css`
        margin-top: 32px;
    `)}
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    & > :first-child {
        margin-right: 8px;

        ${tv(css`
            margin-right: 20px;
        `)}
    }
`;

export const Text = styled.span`
    font-size: 1rem;
`;

export const LockCheckbox = withFocusable(styled.div`
    outline: none;

    &:focus {
        ${CheckboxStyle.Switch} {
            box-shadow: 0 0 0 ${isTV ? '2px' : '1px'} ${Theme.COLORS.PRIMARY_L2};
        }
    }
`);

export const PinCodeWrapper = styled.span`
    position: relative;
    width: 148px;
    height: 35px;

    ${tv(css`
        width: 186px;
        height: 50px;
    `)}

    ${mobile(css`
        width: 135px;
        height: 35px;
    `)}
`;
