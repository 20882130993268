import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfigFront, Games } from 'services';
import { maxCols } from 'helpers';

// in addition to 'all'
const categoriesForColumns = {
    7: ['action', 'adventure', 'kids', 'puzzle', 'sports', 'multiplayer'],
    6: ['action', 'adventure', 'kids', 'sports', 'multiplayer'],
    5: ['action', 'adventure', 'kids', 'multiplayer'],
    4: ['action', 'adventure', 'multiplayer'],
};
// highest session count goes first
const sessionCountCompare = (a, b) => b.sessionCount - a.sessionCount;

export function useCharts() {
    const { t } = useTranslation();

    // Init columns
    const [columns, colsTitles] = useMemo(() => {
        // make copies of game arrays before sorting by session count
        const allGames = [...Games.GetGames()].sort(sessionCountCompare);
        const allCategories = Games.GetCategories().map((category) => ({
            ...category,
            games: [...category.games].sort(sessionCountCompare),
        }));

        let columns = [...Array(maxCols)].map(() => []);
        let categoriesNbCols = maxCols;
        if (!ConfigFront.GetMultiplayer()) {
            // if multiplayer is disabled, get categories from the next row
            // to compensate for the absence of multiplayer from Games.GetCategories
            categoriesNbCols++;
        }

        // restrict categories according to number of columns
        const categories = allCategories.filter((c) =>
            categoriesForColumns[categoriesNbCols].includes(c.name)
        );

        const nbGames = Games.ReducedUI() ? 3 : 10;

        // All stars
        columns[0] = allGames.slice(0, nbGames);
        const colsTitles = [t('charts.all_stars')];

        // Fill rest of cols
        // categories may be empty if for some reason there are no games
        for (let i = 1; i < columns.length && i <= categories.length; i++) {
            columns[i] = categories[i - 1].games.slice(0, nbGames);
            colsTitles.push(
                t(`categories.${categories[i - 1].name.toLowerCase()}`)
            );
        }

        //check if col is empty
        columns = columns.filter((col) => col.length !== 0);

        return [columns, colsTitles];
    }, [t]);

    return { columns, colsTitles };
}
