import Theme from 'app/theme';
import { IconLocked, IconUnlocked } from 'components/ui';
import { avatarURL, If } from 'helpers';
import { forwardRef } from 'react';
import * as S from './Profile.style';

export const Profile = forwardRef(
    ({ data, count, onClick, ...props }, forwardedRef) => {
        return (
            <S.SwiperSlideWrapper onClick={onClick}>
                <S.Wrapper>
                    <S.ProfileAvatar
                        ref={forwardedRef}
                        src={avatarURL(data.avatar.file)}
                        count={count}
                        {...props}
                    />
                    <S.Tag>
                        <S.Text>{data.profileName}</S.Text>
                        <S.Icon>
                            <If condition={data.locked}>
                                <IconLocked fill={Theme.COLORS.WHITE} />
                            </If>
                            <If condition={!data.locked}>
                                <IconUnlocked fill={Theme.COLORS.GRAY_L6} />
                            </If>
                        </S.Icon>
                    </S.Tag>
                </S.Wrapper>
            </S.SwiperSlideWrapper>
        );
    }
);
