const Theme = {
    // Blur
    BLUR_AMOUNT: 39, // Unit : px
    LIGHT_BLUR_BRIGHTNESS: 100, // Unit : %
    DARK_BLUR_BRIGHTNESS: 90, // Unit : %
    DARK_BLUR_BRIGHTNESS_TV: 70, // UNIT : %

    COLORS: {
        // WHITE
        WHITE: '#ffffff',
        BLACK: '#000000',

        // PRIMARY
        PRIMARY_L1: '#a4a9fe',
        PRIMARY_L1_FOCUS: '#a4a9fe',
        PRIMARY_L2: '#3f4ad4',
        PRIMARY_L3: '#262b6d',
        PRIMARY_L3_ALPHA: 'rgba(38, 43, 109, 0.5)', // Tizen does not support hex alpha

        // GRAY
        GRAY_L1: '#d0d0d0',
        GRAY_L2: '#a3a3a3',
        GRAY_L3: '#969696',
        GRAY_L4: '#898989',
        GRAY_L5: '#868686',
        GRAY_L6: '#707070',

        // RED
        RED_L1: '#ea334c',
        RED_L2: '#ff4b60',
        RED_L3: '#ff7777',

        // Orange
        ORANGE_L1: '#f4b43e',

        // Green
        GREEN_L1: '#75fbc8',
    },
};

export default Theme;
