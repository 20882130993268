import { isHP } from 'app/device';
import Routes from 'app/routes';
import { Games } from 'services';

// regular navigation
const baseState = {
    currentTab: {
        name: 'charts',
        subTab: '',
    },

    navigation: {
        highlights: { route: Routes.GAMES.HIGHLIGHTS },
        charts: { route: Routes.GAMES.CHARTS },
        categories: { route: Routes.GAMES.CATEGORIES },
        search: { route: Routes.GAMES.SEARCH },
    },

    settingsVisible: false,
    favoritesVisible: false,
    visible: false,
    subMenuVisible: true,
};

// reduced navigation for hospitality mode and reduced UI mode - no highlights
const reducedUIState = {
    currentTab: {
        name: 'categories',
        subTab: 'all',
    },

    navigation: {
        categories: { route: Routes.GAMES.CATEGORIES },
        charts: { route: Routes.GAMES.CHARTS },
        search: { route: Routes.GAMES.SEARCH },
    },

    settingsVisible: false,
    favoritesVisible: false,
    visible: false,
    subMenuVisible: true,
};

// presented as a function because ReducedUI needs to be evaluated at runtime after
// the games have been loaded
export const makeInitialState = () =>
    isHP || Games.ReducedUI() ? reducedUIState : baseState;
