import { useMemo } from 'react';
import { Games } from 'services';
import { useSelector } from 'react-redux';
import { globalNavigationSelector } from 'slices';
import { maxCols } from 'helpers';

export function useCategories() {
    const { currentTab } = useSelector(globalNavigationSelector);

    // Init columns
    const [columns, games] = useMemo(() => {
        // alphabetical sorting on localized title
        const allGames = Games.GetGames().sort((a, b) =>
            a.assets.title.localeCompare(b.assets.title)
        );
        const columns = [...Array(maxCols)].map(() => []);
        // Fill columns
        const games =
            currentTab.subTab === 'all'
                ? allGames
                : Games.GetCategory(currentTab.subTab);
        games.forEach((game, index) => {
            columns[index % maxCols].push(game);
        });

        return [columns, games];
    }, [currentTab.subTab]);

    return {
        currentTab,
        columns,
        games,
    };
}
