import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { showGlobalMessageBox } from 'slices';
import { useTranslation } from 'react-i18next';

export function useShowError() {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const dispatchErrorMessageBox = useCallback(
        (error) => {
            const {
                message: i18nText,
                httpStatusCode,
                gsStatusCode,
                intStatusCode,
                type,
            } = error;
            let messageToDispatch;

            if (
                (!httpStatusCode || !gsStatusCode) &&
                !intStatusCode &&
                i18nText
            ) {
                messageToDispatch = `${t(i18nText)}`;
            } else if (intStatusCode && i18nText) {
                messageToDispatch = `${t(i18nText)} (W-INT-${intStatusCode})`;
            } else if (i18nText && httpStatusCode && gsStatusCode) {
                messageToDispatch = `${t(
                    i18nText
                )} (W-${httpStatusCode}-${gsStatusCode})`;
            } else {
                console.error('dispatchErrorMessageBox : Error leak', error);
                messageToDispatch = t('error.internal');
            }

            dispatch(
                showGlobalMessageBox({
                    type,
                    message: messageToDispatch,
                })
            );
        },
        [dispatch, t]
    );

    return { dispatchErrorMessageBox };
}
