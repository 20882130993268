import { isIOS, isMobileOrTablet } from 'app/device';
import Theme from 'app/theme';
import logo from 'assets/images/app_icon.png';
import { NeutralButton, RowLayout } from 'components/ui/index.js';
import { MessageBoxTitle, MessageBoxWrapper } from 'components/ui/message-box';
import { Message } from 'components/ui/message-box/MessageBox.style';
import { If } from 'helpers';
import { useBackPress } from 'hooks';
import QRCodeStyling from 'qr-code-styling';
import { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import * as S from './NoAccountBox.style.js';

const qrCode = new QRCodeStyling({
    width: 300,
    height: 300,
    image: logo,
    dotsOptions: {
        color: Theme.COLORS.BLACK,
        type: 'extra-rounded',
    },
    imageOptions: {
        crossOrigin: 'anonymous',
        margin: 10,
    },
    backgroundOptions: {
        color: Theme.COLORS.WHITE,
    },
});

export function NoAccountBox({ onClose, registerUrl }) {
    const { t } = useTranslation();
    const ref = useRef(null);
    useBackPress(onClose);

    useEffect(() => {
        qrCode.append(ref.current);
        qrCode.update({ data: registerUrl });
    }, [registerUrl]);

    const openLink = () => {
        window.open(registerUrl, isIOS ? '_self' : '_blank');
    };

    // Note: nested backdrop-filters does not work
    const box = (
        <MessageBoxWrapper>
            <MessageBoxTitle title={t('login.register_link')} />
            <Message>{t('login.subscribe')}</Message>
            <S.RegisterUrl onClick={openLink} target="_blank">
                {registerUrl}
            </S.RegisterUrl>
            <If condition={!isMobileOrTablet && process.env.REACT_APP_QR_CODE}>
                <Message>{t('login.scan_qrcode')}</Message>
                <S.QrCode ref={ref} />
            </If>
            <RowLayout>
                <NeutralButton onClick={onClose} bold>
                    {t('buttons.cancel')}
                </NeutralButton>
            </RowLayout>
        </MessageBoxWrapper>
    );

    return ReactDOM.createPortal(box, document.body);
}
