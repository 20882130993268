import { IconHeart } from 'components/ui';
import { assetURL, If } from 'helpers';
import { useGameItem } from 'hooks';
import React from 'react';
import { Games } from 'services';
import * as S from './GameItem.style';

export const GameItem = React.forwardRef(
    ({ isGrid, game, src, ...props }, ref) => {
        const { isFavorite, favIconRef, animateAndToggle } = useGameItem(game);

        const GameComponent = isGrid ? S.GameGridItem : S.GameItem;
        const FavoriteIcon = isGrid ? S.FavoriteGridItem : S.FavoriteIcon;

        return (
            <GameComponent
                ref={ref}
                {...props}
                srcImage={src ? assetURL(src) : ''}
                data-alias={game.alias}
            >
                <If condition={Games.FavoritesEnabled()}>
                    <FavoriteIcon
                        key={`favicon-${game.alias}`}
                        ref={favIconRef}
                        checked={isFavorite}
                        onClick={animateAndToggle}
                        disableMoveUp={true} // do not move out of window (to menu bar)
                    >
                        <IconHeart />
                    </FavoriteIcon>
                </If>

                {/* no tags in v1.1.x
                <If condition={game.release_status === 'new' || game.has4k}>
                    <S.TagsWrapper>
                        <Tags
                            isNew={game.release_status === 'new'}
                            is4K={game.has4k}
                        />
                    </S.TagsWrapper>
                </If>
                */}

                <If condition={!src}>
                    <S.GameItemTextFallback>
                        {game.assets.title}
                    </S.GameItemTextFallback>
                </If>
            </GameComponent>
        );
    }
);
