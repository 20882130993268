import { isSafari, isTV } from 'app/device';
import history from 'app/history';
import Routes from 'app/routes';
import { useSpatialNavContext } from 'context';
import { setMessageBoxCloseCallback, useShowError } from 'hooks';
import { useCallback, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ConfigFront } from 'services';
import {
    doLogin,
    doLogout,
    globalSelector,
    loginSelector,
    MaintenanceStatus,
    showGlobalMessageBox,
} from 'slices';

// see public/var.js
// eslint-disable-next-line no-undef
const appName = APP_TITLE || process.env.REACT_APP_TITLE;

const isGamepadWarningVisible = () => {
    if (isTV || !isSafari) return false; // warning shown only on Safari (caution, Tizen browser reports as Safari)

    //if warning doesnt exist in local storage, create it
    if (!localStorage.getItem('gamepad_warning_safari')) {
        localStorage.setItem('gamepad_warning_safari', new Date().toString());
        return true;
    }

    const storedDate = Date.parse(
        localStorage.getItem('gamepad_warning_safari')
    );
    const dateNow = new Date();
    const diff = Math.round((dateNow - storedDate) / 1000 / 60);

    // if more than 3 hours
    if (diff > 4) {
        // reset date
        localStorage.setItem('gamepad_warning_safari', new Date().toString());
        return true;
    }
    return false;
};

export function useLogin() {
    const dispatch = useDispatch();
    const { isChecking, loggedIn, loginError, hasLoginError, userRole } =
        useSelector(loginSelector);
    const { maintenanceStatus } = useSelector(globalSelector);
    const supportMethods = ConfigFront.GetSupportMethods();
    const registerUrl = ConfigFront.GetRegisterUrl();
    const { register, handleSubmit } = useForm();
    const { dispatchErrorMessageBox } = useShowError();
    const { t } = useTranslation();
    const { setFocus } = useSpatialNavContext();
    const wrapperToFocus = useRef();
    const LoginBoxRef = useRef();

    const isInternalUser = userRole === 'tester' || userRole === 'gamestream';

    // gamepad warning should be displayed only if not in maintenance
    const gamepadWarningVisible =
        isGamepadWarningVisible() &&
        maintenanceStatus === MaintenanceStatus.NORMAL;

    const updateConfig = useCallback(() => {
        return ConfigFront.LoadPostLogin();
    }, []);

    // show gamepad warning
    useEffect(() => {
        if (!gamepadWarningVisible) {
            return;
        }
        if (loggedIn) {
            updateConfig().then(() => {
                dispatch(
                    showGlobalMessageBox({
                        type: 'info',
                        message: t('warnings.gamepad.activate'),
                        timing: 5000,
                        routeTo: Routes.MEGA_LOADER,
                    })
                );
            });
        } else {
            const wrapper = wrapperToFocus.current;
            if (wrapper) {
                setMessageBoxCloseCallback(() => {
                    setFocus(wrapper);
                });
            }
            dispatch(
                showGlobalMessageBox({
                    type: 'info',
                    message: t('warnings.gamepad.activate'),
                    timing: 5000,
                })
            );
        }
    }, [dispatch, gamepadWarningVisible, loggedIn, setFocus, t, updateConfig]);

    // show maintenance warnings
    useEffect(() => {
        if (
            gamepadWarningVisible ||
            !loggedIn ||
            maintenanceStatus === MaintenanceStatus.NORMAL
        ) {
            return;
        }
        // partial maintenance and not a tester or GS member and already loggedIn
        if (
            maintenanceStatus === MaintenanceStatus.INTERNAL_ONLY &&
            !isInternalUser
        ) {
            // Logout and show maintenance message
            dispatch(doLogout()).then(() => {
                dispatch(
                    showGlobalMessageBox({
                        type: 'error',
                        timing: 5000,
                        message: t('login.maintenance', { appName }),
                    })
                );
            });
        }
        // partial maintenance and tester or GS member
        else if (
            maintenanceStatus === MaintenanceStatus.INTERNAL_ONLY &&
            isInternalUser
        ) {
            // show message maintenance but redirect to loader and show app
            updateConfig().then(() => {
                dispatch(
                    showGlobalMessageBox({
                        type: 'info',
                        message: t('login.maintenance', { appName }),
                        timing: 5000,
                        routeTo: Routes.MEGA_LOADER,
                    })
                );
            });
        }
    }, [
        dispatch,
        gamepadWarningVisible,
        isInternalUser,
        loggedIn,
        maintenanceStatus,
        t,
        updateConfig,
    ]);

    // actual login
    useEffect(() => {
        if (
            gamepadWarningVisible ||
            !loggedIn ||
            maintenanceStatus !== MaintenanceStatus.NORMAL
        ) {
            return;
        }
        // update config and move on
        updateConfig().then(() => {
            history.push(Routes.MEGA_LOADER);
        });
    }, [gamepadWarningVisible, loggedIn, maintenanceStatus, updateConfig]);

    useEffect(() => {
        // Show login error
        if (hasLoginError) {
            dispatchErrorMessageBox(loginError);
        }
    }, [dispatchErrorMessageBox, hasLoginError, loginError]);

    const onSubmit = handleSubmit((data) => {
        dispatch(
            doLogin({
                username: data.username,
                password: data.password,
                rememberMe: data.rememberMe,
            })
        );
    });

    return {
        register,
        onSubmit,
        isChecking,
        loggedIn,
        LoginBoxRef,
        supportMethods,
        registerUrl,
        wrapperToFocus,
    };
}
