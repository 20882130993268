import { LogoNav, Menu } from 'components/ui';
import { SpatialNavSection, useSpatialNavContext } from 'context';
import { useGlobalNavigation } from 'hooks';
import { useLayoutEffect, useRef } from 'react';
import { Games } from 'services';
import { AsideNav } from './aside-nav/AsideNav';
import * as S from './GlobalNavigation.style';

export function GlobalNavigation() {
    const {
        navigation,
        currentTab,
        visible,
        subMenuVisible,
        onTabSelected,
        onSubTabSelected,
    } = useGlobalNavigation();
    const menuWrapperRef = useRef();
    const { refocus, setFocus, shouldRefocus } = useSpatialNavContext();

    // force focus on navbar when visible and a focus reset is necessary
    useLayoutEffect(() => {
        if (visible && shouldRefocus) {
            refocus(menuWrapperRef.current);
        }
    }, [refocus, shouldRefocus, visible]);

    const showSubMenu = subMenuVisible && !Games.ReducedUI();

    return (
        <S.Wrapper isVisible={visible}>
            <S.Content>
                <S.LogoWrapper>
                    <LogoNav />
                </S.LogoWrapper>
                <SpatialNavSection focusOnMount enterTo="last-focused">
                    <S.MenuWrapper ref={menuWrapperRef}>
                        <Menu
                            items={Object.keys(navigation)}
                            namespace="navigation"
                            onItemSelected={onTabSelected}
                        />
                    </S.MenuWrapper>
                </SpatialNavSection>
                <S.AsideNavWrapper>
                    <AsideNav menuWrapperRef={menuWrapperRef} />
                </S.AsideNavWrapper>
            </S.Content>
            {navigation[currentTab.name]?.subMenu && showSubMenu && (
                <SpatialNavSection enterTo="last-focused">
                    <S.SubContent>
                        <Menu
                            items={navigation[currentTab.name].subMenu}
                            namespace={currentTab.name}
                            onItemSelected={onSubTabSelected}
                            preventMoveRight={true}
                            overrideMoveUp={() =>
                                setFocus(menuWrapperRef.current)
                            }
                        />
                    </S.SubContent>
                </SpatialNavSection>
            )}
        </S.Wrapper>
    );
}
