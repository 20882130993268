import { PrimaryButton } from 'components/ui';
import { useTranslation } from 'react-i18next';
import * as S from '../ActionButtons.style';
import { useMultiViewContext } from 'context/multi-view';
import { EulaButton } from 'components/games';
import { useBackPress } from 'hooks';

export function ActionButtons({ game }) {
    const { t } = useTranslation();
    const { setView } = useMultiViewContext();

    const close = () => setView('description');
    useBackPress(close);

    return (
        <S.Wrapper>
            <PrimaryButton
                defaultElement
                focusOnMount
                onClick={close}
                disableMoveRight
            >
                {t('buttons.ok')}
            </PrimaryButton>
            <EulaButton game={game} disableMoveRight />
        </S.Wrapper>
    );
}
