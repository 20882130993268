import { shallowEqual } from 'helpers';

const repeatTimeout = 420;
const repeatDelay = 60;

class GamepadListener {
    constructor() {
        this.debug = false;
        this.count = 0;
        this.stopped = true;
        this.state = {
            Left: 0,
            Down: 0,
            Right: 0,
            Up: 0,
            Select: 0,
            Back: 0,
            TriggerBottomLeft: 0,
            TriggerBottomRight: 0,
            //  ActionTop = ∆ or Y (PS4 vs Xbox)
            ActionTop: 0,
            Home: 0,
        };
        this.repeat = false;
        this.lastStateChange = Date.now();
    }

    Start(dispatchCallback) {
        if (!this.stopped) return;

        this.dispatchCallback = dispatchCallback;
        this.stopped = false;
        if (navigator.getGamepads) this.Poll();
    }

    Stop() {
        this.stopped = true;
    }

    Count() {
        return this.count;
    }

    Poll() {
        if (this.stopped) return;

        let gamepads = navigator.getGamepads();
        this.count = 0;
        // Count how many connected gamepads
        for (let i = 0; i < gamepads.length; i++) {
            if (gamepads[i] && gamepads[i].connected) this.count++;
        }

        // Take only the first one
        for (let i = 0; i < gamepads.length; i++) {
            let controller = gamepads[i];
            if (controller && controller.connected) {
                let axes = [];

                if (controller.axes) {
                    for (let a = 0, x = controller.axes.length; a < x; a++)
                        axes.push(controller.axes[a].toFixed(2));
                }

                let leftStick = [axes[0], axes[1]];
                // TODO: check iOS + Chrome desktop returning several extra axes?
                let rightStick = [axes[2], axes[3]];

                let newState = {
                    Left: 0,
                    Down: 0,
                    Right: 0,
                    Up: 0,
                    Left2: 0,
                    Down2: 0,
                    Right2: 0,
                    Up2: 0,
                    Select: 0,
                    Back: 0,
                    TriggerBottomLeft: 0,
                    TriggerBottomRight: 0,
                    ActionTop: 0,
                    Home: 0,
                };

                // left stick
                if (leftStick[0] > 0.7) {
                    newState.Right = 1;
                } else if (leftStick[0] < -0.7) {
                    newState.Left = 1;
                } else if (leftStick[1] > 0.7) {
                    newState.Down = 1;
                } else if (leftStick[1] < -0.7) {
                    newState.Up = 1;
                }

                // right stick
                if (rightStick[0] > 0.7) {
                    newState.Right2 = 1;
                } else if (rightStick[0] < -0.7) {
                    newState.Left2 = 1;
                } else if (rightStick[1] > 0.7) {
                    newState.Down2 = 1;
                } else if (rightStick[1] < -0.7) {
                    newState.Up2 = 1;
                }

                // Buttons
                for (i = 0; i < controller.buttons.length; i++) {
                    let val = controller.buttons[i];
                    // eslint-disable-next-line eqeqeq
                    let pressed = val == 1.0;

                    if (typeof val == 'object') {
                        pressed = val.pressed;
                        val = val.value;
                    }
                    if (pressed) {
                        if (this.debug) console.log('INPUT GAMEPAD VALUE', i);

                        //eslint-disable-next-line default-case
                        switch (i) {
                            case 14:
                                newState.Left = 1;
                                break;
                            case 12:
                                newState.Up = 1;
                                break;
                            case 15:
                                newState.Right = 1;
                                break;
                            case 13:
                                newState.Down = 1;
                                break;
                            case 0:
                                newState.Select = 1;
                                break;
                            case 1:
                                newState.Back = 1;
                                break;
                            case 6:
                                // 6 = trigger bottom left PS4
                                newState.TriggerBottomLeft = 1;
                                break;
                            case 7:
                                // 7 = trigger bottom right PS4
                                newState.TriggerBottomRight = 1;
                                break;
                            case 3:
                                // Y or ∆
                                newState.ActionTop = 1;
                                break;
                            case 16:
                                // /!\ mapping may differ depending on browser used
                                newState.Home = 1;
                                break;
                        }
                    }
                }

                // auto-repeat
                const now = Date.now();
                if (shallowEqual(this.state, newState)) {
                    if (
                        now - this.lastStateChange <
                        (this.repeat ? repeatDelay : repeatTimeout)
                    ) {
                        break;
                    }
                    this.repeat = true;
                } else {
                    this.repeat = false;
                }
                this.lastStateChange = now;

                //joystick left
                if (newState.Left) this.dispatchCallback('left');

                if (newState.Up) this.dispatchCallback('up');

                if (newState.Right) this.dispatchCallback('right');

                if (newState.Down) this.dispatchCallback('down');

                //joystick right
                if (newState.Left2) this.dispatchCallback('left2');

                if (newState.Up2) this.dispatchCallback('up2');

                if (newState.Right2) this.dispatchCallback('right2');

                if (newState.Down2) this.dispatchCallback('down2');

                // do not auto-repeat buttons
                if (newState.Select && newState.Select !== this.state.Select)
                    this.dispatchCallback('select');

                if (newState.Back && newState.Back !== this.state.Back)
                    this.dispatchCallback('back');

                if (
                    newState.TriggerBottomLeft &&
                    newState.TriggerBottomLeft !== this.state.TriggerBottomLeft
                )
                    this.dispatchCallback('triggerBottomLeft');

                if (
                    newState.TriggerBottomRight &&
                    newState.TriggerBottomRight !==
                        this.state.TriggerBottomRight
                )
                    this.dispatchCallback('triggerBottomRight');

                if (
                    newState.ActionTop &&
                    newState.ActionTop !== this.state.ActionTop
                )
                    this.dispatchCallback('actionTop');

                if (newState.Home && newState.Home !== this.state.Home)
                    this.dispatchCallback('home');

                this.state = newState;

                break;
            }
        }
        // Dont use requestAnimationFrame as it interferes with scrollArea animation
        setTimeout(this.Poll.bind(this), 60);
    }
}

export default GamepadListener;
