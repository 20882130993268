import styled from 'styled-components';

// Message Wrapper
export const MessageWrapper = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 0.938rem;
`;

export const Text = styled.div`
    align-self: flex-end;
    padding-bottom: 0.25rem;
    margin-left: 0.75rem;
`;

export const Icon = styled.div`
    width: 2.5rem;
    height: 2.5rem;

    svg {
        fill: white;
    }
`;

export const Message = styled.div`
    margin-bottom: 24px;
    line-height: 1.5rem;
`;

export const MultipleMessagesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.938rem;

    ${Icon} {
        margin-bottom: 0.938rem;
    }

    ${Text} {
        align-self: auto;
    }
`;

export const BoxTitleWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1rem;
`;

export const BoxTitle = styled.div`
    font-size: 1.5rem;
`;

export const IconTitle = styled.div`
    width: 1.25rem;
    display: inline-block;
    margin-right: 4px;

    svg {
        fill: white;
    }
`;
