import { RegularButton } from 'components/ui';
import { useTranslation } from 'react-i18next';
import * as S from './ActionButtons.style';
import { useMultiViewContext } from 'context/multi-view';
import { JoinLobbyButton } from 'components/games/join-lobby/JoinLobbyButton';
import { useBackPress } from 'hooks';

import { isMobile } from 'app/device';
import { gameLobbySelector, LOBBY_STATE } from 'slices';
import { useSelector } from 'react-redux';

export function ActionButtons({ game }) {
    const { t } = useTranslation();
    const { setView } = useMultiViewContext();
    const { lobbyState } = useSelector(gameLobbySelector);

    // disableMoves are set below to prevent moving out of the join/quit pair and accidentally quitting the lobby once a session is starting
    // check on isMobile is to account for the vertical/horizontal layouts on mobile vs desktop/tv
    const pending = lobbyState !== LOBBY_STATE.INITIAL;

    const close = () => setView('description');
    useBackPress(close);

    return (
        <S.Wrapper>
            <JoinLobbyButton
                game={game}
                focusOnMount
                disableMoveUp={pending}
                disableMoveLeft={pending}
                disableMoveRight={pending && isMobile}
            />
            <RegularButton
                onClick={close}
                disableMoveUp={pending && !isMobile}
                disableMoveRight={true}
            >
                {t('buttons.exit')}
            </RegularButton>
        </S.Wrapper>
    );
}
