import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    message: null,
    message2: null,
    type: 'error',
    routeTo: null,
    timing: null,
};

// Slice
const globalMessageBoxSlice = createSlice({
    name: 'globalMessageBox',

    initialState: initialState,

    reducers: {
        showGlobalMessageBox: (state, { payload }) => {
            state.message = payload.message;
            state.message2 = payload.message2;
            state.routeTo = payload.routeTo;
            state.timing = payload.timing;

            if (payload.type) state.type = payload.type;
        },

        hideGlobalMessageBox: () => {
            return initialState;
        },
    },
});

// Actions
export const { showGlobalMessageBox, hideGlobalMessageBox } =
    globalMessageBoxSlice.actions;

// Selector
export const globalMessageBoxSelector = (state) => state.globalMessageBox;

// Reducer
export const globalMessageBoxReducer = globalMessageBoxSlice.reducer;
