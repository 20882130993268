import * as S from './SupportMessageBox.style';
import { MessageBoxWrapper, MessageBoxTitle } from 'components/ui/message-box';
import { Message } from 'components/ui/message-box/MessageBox.style';
import { PrimaryButton } from 'components/ui/index';
import { useTranslation } from 'react-i18next';
import { If } from 'helpers';
import React from 'react';
import ReactDOM from 'react-dom';
import { useBackPress } from 'hooks';

export function SupportMessageBox({ onClose, supportMethods }) {
    const { t } = useTranslation();
    useBackPress(onClose);

    const message = t('support.message');

    const box = (
        <MessageBoxWrapper>
            <MessageBoxTitle title={t('support.title')} />
            <S.BuildVersion>
                {t('support.version')} {process.env.REACT_APP_VERSION}
            </S.BuildVersion>
            <If condition={message}>
                <Message>
                    {message}
                    {supportMethods.length > 0 ? <span>&nbsp;</span> : null}
                    {supportMethods.map((method, i) => {
                        const methodName = Object.keys(method)[0];
                        const methodValue = method[methodName];
                        return (
                            <React.Fragment key={i}>
                                <S.SupportLink
                                    href={
                                        methodName === 'email'
                                            ? `mailto:${methodValue}`
                                            : `${methodValue}`
                                    }
                                    target="_blank"
                                >
                                    {methodValue}
                                </S.SupportLink>
                                {i < supportMethods.length - 1 ? ' - ' : null}
                            </React.Fragment>
                        );
                    })}
                </Message>
            </If>
            <S.CloseWrapper>
                <PrimaryButton onClick={onClose}>
                    {t('buttons.close')}
                </PrimaryButton>
            </S.CloseWrapper>
        </MessageBoxWrapper>
    );
    return ReactDOM.createPortal(box, document.body);
}
