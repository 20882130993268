import styled from 'styled-components';

export const BoxTitleWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1rem;
    width: 100%;
`;

export const BoxTitle = styled.div`
    font-size: 1.5rem;
`;

export const IconTitle = styled.div`
    width: 1.25rem;
    display: inline-block;
    margin-right: 4px;
    position: relative;
    height: 1.8rem;

    svg {
        position: absolute;
        top: 0;
        left: 0;
        fill: white;
    }
`;
