import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { backendUrl } from 'api/client/ClientOptions';
import axios from 'axios';

export const MaintenanceStatus = {
    INITIAL: -1,
    NORMAL: 0,
    INTERNAL_ONLY: 1,
    MAINTENANCE: 2,
};

// Thunks
export const checkMaintenance = createAsyncThunk(
    'global/checkMaintenance',
    async () => {
        const URL = `${backendUrl}/maintenance.json`;
        const { data } = await axios.get(URL);
        return data.maintenance;
    }
);

// Slice
const globalSlice = createSlice({
    name: 'global',

    initialState: {
        maintenanceStatus: MaintenanceStatus.INITIAL,
    },

    extraReducers: {
        [checkMaintenance.fulfilled]: (state, { payload }) => {
            state.maintenanceStatus = payload;
        },

        [checkMaintenance.rejected]: (state) => {
            state.maintenanceStatus = MaintenanceStatus.MAINTENANCE;
        },
    },
});

// Selector
export const globalSelector = (state) => state.global;

// Reducer
export const globalReducer = globalSlice.reducer;
