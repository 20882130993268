import * as S from './Hospitality.style';
import { useHospitalityAd } from 'hooks';
import { If } from 'helpers';
import { Video } from 'components/games/trailer/Trailer.style';

export function HospitalityAd() {
    const hospitalityAd = useHospitalityAd();

    return (
        <If condition={hospitalityAd.isVisible && hospitalityAd.url}>
            <S.Wrapper>
                <Video autoPlay muted loop onClick={(e) => e.preventDefault()}>
                    <source type="video/mp4" src={hospitalityAd.url} />
                </Video>
            </S.Wrapper>
        </If>
    );
}
