import { RowLayout } from 'components/ui';
import { GameDescription } from 'components/games';
import { ActionButtons } from './ActionButtons';
import * as View from '../View.style';
import * as S from './DescriptionView.style';
import { useEffect } from 'react';
import { useMultiViewContext } from 'context/multi-view';

export function DescriptionView({
    game,
    onUnFavorite,
    onQuickMatch,
    onExpandClicked,
    playButtonRef,
}) {
    const { view, viewRenderCount } = useMultiViewContext();

    useEffect(() => {
        if (view === 'description' && viewRenderCount.current.moreView > 0)
            playButtonRef.current.focus();
    }, [playButtonRef, view, viewRenderCount]);

    return (
        <RowLayout fullSize>
            <View.LeftPan>
                <ActionButtons
                    game={game}
                    playButtonRef={playButtonRef}
                    onUnFavorite={onUnFavorite}
                    onQuickMatch={onQuickMatch}
                />
            </View.LeftPan>
            <View.RightPan>
                <S.Content>
                    <GameDescription
                        onExpandClicked={onExpandClicked}
                        playButtonRef={playButtonRef}
                        game={game}
                        minimized={true}
                    />
                </S.Content>
            </View.RightPan>
        </RowLayout>
    );
}
