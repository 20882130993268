import { Endpoints, PrivateClient as client } from 'api';
import { doRequest, StatusCodes } from 'helpers';

import { supportedLanguages } from 'i18n';

class ConfigFront {
    constructor() {
        // base config from config/front/alias call
        this.baseConfig = {};
        // full config from base config + optional user-specific overrides
        this.config = {};
    }

    isAdActive() {
        return this.config.ad_words;
    }

    GetAdInfos() {
        return {
            interval: this.config.interval_ad_words,
            url: this.config.path_ad_words,
        };
    }

    GetConfig() {
        return this.config;
    }

    GetBackendVersion() {
        const { backend_version } = this.config;
        if (!backend_version) {
            return {
                major: 0,
                minor: 0,
                patch: 0,
            };
        }
        // vX.Y.Z + extra info - Y, Z and extra may be missing
        const matches = this.config.backend_version?.match(
            /^v(\d+)(?:\.(\d+))?(?:\.(\d+))?(.*)?/
        );
        if (matches) {
            return {
                major: parseInt(matches[1], 10),
                minor: parseInt(matches[2]) || 0,
                patch: parseInt(matches[3]) || 0,
                extraInfo: matches[4],
            };
        }
        // other string, typically a build tag for newer versions
        return {
            major: 99999,
            minor: 99999,
            patch: 99999,
        };
    }

    GetAssetsUrl() {
        // allow override from env in development, otherwise use value from backend config
        const envUrl =
            process.env.NODE_ENV === 'development' &&
            process.env.REACT_APP_ASSETS_URL;
        return envUrl || this.config.assets_server;
    }

    GetChatServerUrl() {
        // allow override from env in development, otherwise use value from backend config
        const envUrl =
            process.env.NODE_ENV === 'development' &&
            process.env.REACT_APP_CHAT_SERVER_URL;
        return (
            envUrl ||
            this.config.chat_web_socket_host +
                ':' +
                this.config.chat_web_socket_port
        );
    }

    GetRegisterUrl() {
        return this.config.how_to_register_url;
    }

    GetResetPasswordUrl() {
        return this.config.change_password_url;
    }

    // /!\ all language-related information always comes from the base configuration
    // and cannot be overridden
    GetSupportedLanguages() {
        return this.baseConfig.languages
            .split(',')
            .filter((lang) => supportedLanguages.includes(lang));
    }

    GetDefaultLanguage() {
        return this.baseConfig.default_language;
    }

    GetSupportMethods() {
        let methods = [];

        if (this.config.support_email)
            methods.push({ email: this.config.support_email });

        if (this.config.support_url)
            methods.push({ url: this.config.support_url });

        return methods;
    }

    GetMultiplayer() {
        return this.config.feature_multiplayer;
    }

    LoadInitial(alias) {
        // config/front/alias returns a base config for all users
        return doRequest({
            request: client.get(`${Endpoints.CONFIG_FRONT}/${alias}`),
            [StatusCodes.OK]: ({ data }) => {
                this.baseConfig = data;
                this.config = { ...data };
                return this.config;
            },
        });
    }

    LoadPostLogin() {
        // config/front with no alias returns optional, additional user-specific overrides
        return doRequest({
            request: client.get(`${Endpoints.CONFIG_FRONT}`),
            [StatusCodes.OK]: ({ data }) => {
                // start from base config and override with user-specific data
                this.config = { ...this.baseConfig, ...data };
                return this.config;
            },
        });
    }
}

export default new ConfigFront();
