import { useMemo } from 'react';
import { maxCols } from 'helpers';
import { Games } from 'services';

export function useHighLights() {
    const { highlighted, games } = Games.GetHighLights();

    // Init columns
    const columns = useMemo(() => {
        const result = [...Array(maxCols)].map(() => []);
        games
            .filter((g) => g !== highlighted)
            .forEach((game, index) => result[index % maxCols].push(game));
        return result;
    }, [games, highlighted]);

    return { highlighted, columns, games };
}
