import { isMobile } from 'app/device';
import { NoAccountBox } from 'components/ui';
import { BlurButton } from 'components/ui/buttons/blur-button/BlurButton';
import { SupportMessageBox } from 'components/ui/message-box/support-message-box/SupportMessageBox';
import { If } from 'helpers';
import { useBottomButtons } from 'hooks/bottom-buttons/useBottomButtons';
import { useTranslation } from 'react-i18next';
import * as S from './BottomButtons.style';

export function BottomButtons({
    registerUrl,
    supportMethods,
    supportVisible,
    setSupportVisible,
    wrapperToHide,
    ...props
}) {
    const BottomButtons = useBottomButtons(wrapperToHide);
    const { t } = useTranslation();

    return (
        <S.Wrapper {...props}>
            <If condition={BottomButtons.supportVisible}>
                <SupportMessageBox
                    onClose={BottomButtons.hideSupport}
                    supportMethods={supportMethods}
                />
            </If>

            <If condition={BottomButtons.noAccountVisible}>
                <NoAccountBox
                    registerUrl={registerUrl}
                    onClose={BottomButtons.hideNoAccount}
                />
            </If>

            <If condition={isMobile}>
                <span onClick={BottomButtons.showSupport}>
                    {t('login.support')}
                </span>
                <span onClick={BottomButtons.showNoAccount}>
                    {t('login.register_link')}
                </span>
            </If>

            <If condition={!isMobile}>
                <BlurButton
                    title={t('login.support')}
                    onClick={BottomButtons.showSupport}
                />
                <If condition={registerUrl}>
                    <BlurButton
                        title={t('login.register_link')}
                        onClick={BottomButtons.showNoAccount}
                    />
                </If>
            </If>
        </S.Wrapper>
    );
}
