import { IconWarning, Logo, PrimaryButton } from 'components/ui';
import * as S from './MaintenanceScreen.style';
import Theme from 'app/theme';
import { If } from 'helpers';
import { isTV } from 'app/device';
import { useTranslation } from 'react-i18next';

// see public/var.js
// eslint-disable-next-line no-undef
const appName = APP_TITLE || process.env.REACT_APP_TITLE;

export function MaintenanceScreen() {
    const { t } = useTranslation();

    const quitApp = () => {
        window.close();
    };

    return (
        <S.Wrapper>
            <S.InnerWrapper>
                <Logo />
                <S.Content>
                    <S.WarningWrapper>
                        <IconWarning fill={Theme.COLORS.ORANGE_L1} />
                    </S.WarningWrapper>
                    <S.Text>{t('login.maintenance', { appName })}</S.Text>
                </S.Content>
                <If condition={!isTV}>
                    <PrimaryButton onClick={quitApp}>
                        {t('buttons.exit')}
                    </PrimaryButton>
                </If>
            </S.InnerWrapper>
        </S.Wrapper>
    );
}
