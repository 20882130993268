import { useCallback, useState } from 'react';
import Routes from 'app/routes';
import { useDispatch } from 'react-redux';
import { doLogout } from 'slices';
import history from 'app/history';

export function useLogout() {
    const [visible, setVisible] = useState(false);
    const dispatch = useDispatch();

    const callbacks = {
        doLogout: (callback) => {
            dispatch(doLogout()).then(() => {
                setVisible(false);
                history.push(Routes.LOGIN);
                if (typeof callback === 'function') callback();
            });
        },

        declineLogout: useCallback(() => {
            setVisible(false);
        }, []),

        showLogoutBox: useCallback(() => {
            setVisible(true);
        }, []),
    };

    return { visible, callbacks };
}
