import * as S from './LangSelection.style';
import { LangIcon } from 'components/ui';
import { SpatialNavProvider, SpatialNavSection } from 'context';
import { useLanguageSelect } from 'hooks';
import { useBackPress } from 'hooks';

import Config from 'services/ConfigFront';

export function LangSelection(props) {
    const languages = Config.GetSupportedLanguages();
    const { lang, changeLanguage } = useLanguageSelect();

    // On Back button pressed
    useBackPress(() => changeLanguage(lang));

    return (
        <SpatialNavProvider>
            <SpatialNavSection enterTo="default-element" focusOnMount>
                <S.LangSelectionWrapper {...props}>
                    {languages.map((language, idx) => (
                        <LangIcon
                            defaultElement={language === lang}
                            key={idx}
                            lang={language}
                            onClick={() => changeLanguage(language)}
                        />
                    ))}
                </S.LangSelectionWrapper>
            </SpatialNavSection>
        </SpatialNavProvider>
    );
}
