import history from 'app/history';
import Routes from 'app/routes';
import { useSpatialNavContext } from 'context';
import {
    useLanguageSelect,
    useLogout,
    useQuit,
    useSupportMessageBox,
} from 'hooks';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    globalNavigationSelector,
    resetGlobalNavigation,
    setFavoritesVisible,
    setSettingsVisible,
    toggleSettings,
} from 'slices';

export function useAsideNav() {
    const { favoritesVisible, settingsVisible } = useSelector(
        globalNavigationSelector
    );
    const dispatch = useDispatch();
    const settingsButtonRef = useRef();
    const settingsFirstFocus = useRef(true);
    const logout = useLogout();
    const quit = useQuit();
    const language = useLanguageSelect();
    const support = useSupportMessageBox();
    const [settingsHover, setSettingsHover] = useState(false);
    const { resetLastFocused } = useSpatialNavContext();

    const toggleSettingsState = () => {
        setSettingsHover(!settingsHover);
    };

    const onFavoritesFocus = () => {
        // Route to Favorites
        history.push(Routes.GAMES.FAVORITES);

        dispatch(setFavoritesVisible(true));
        dispatch(setSettingsVisible(false));
    };

    const onFavoritesBlur = () => {
        dispatch(setFavoritesVisible(false));
    };

    const onSettingsClick = () => {
        if (!settingsFirstFocus.current) dispatch(toggleSettings());
        settingsFirstFocus.current = false;
    };

    const onSettingsFocus = () => {
        settingsFirstFocus.current = true;
        dispatch(setSettingsVisible(true));
    };

    const hideSettings = () => {
        dispatch(setSettingsVisible(false));
    };

    const doLogout = () => {
        logout.callbacks.doLogout(() => {
            // hide the nav bar
            dispatch(resetGlobalNavigation());
            // reset last focus in nav bar - only once hidden
            resetLastFocused();
        });
    };

    return {
        favoritesVisible,
        settingsVisible,
        onFavoritesFocus,
        onFavoritesBlur,
        onSettingsClick,
        onSettingsFocus,
        settingsButtonRef,
        logout,
        quit,
        support,
        language,
        doLogout,
        toggleSettingsState,
        settingsHover,
        hideSettings,
    };
}
