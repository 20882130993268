import { isHP } from 'app/device';
import Routes from 'app/routes';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Games } from 'services';
import { getFavorites, showGlobalNavigation } from 'slices';

export function useStartNavigation() {
    const dispatch = useDispatch();
    const history = useHistory();

    return useCallback(
        (profileUID, pinCode) => {
            // Init Favorites Slice
            dispatch(getFavorites({ profileUID, pinCode }));
            // load stats
            Games.SetStats(profileUID);

            // Route to games
            dispatch(showGlobalNavigation());
            history.push(
                isHP ? Routes.GAMES.CATEGORIES : Routes.GAMES.HIGHLIGHTS
            );
        },
        [dispatch, history]
    );
}
