import LogoNavImage from 'assets/images/logo-nav.png';
import styled, { css } from 'styled-components';
import { tv, mobile, mobileSmall } from 'app/device';

export const LogoNav = styled.div.attrs((props) => ({
    style: {
        backgroundImage: `url(${LogoNavImage})`,
        ...props.style,
    },
}))`
    display: block;
    background-repeat: no-repeat;
    /* background-size: 100% 100%; */
    background-position: center center;
    background-size: contain;
    width: 187px;
    height: 40px;

    ${mobile(css`
        width: 100%;
        height: 30px;
    `)}

    ${mobileSmall(css`
        width: 100%;
        height: 30px;
    `)}

  ${tv(css`
        width: 100%;
        height: 40px;
    `)}
`;
