import { isMobileOrTablet, isTV } from 'app/device';
import SwiperCore, { Virtual } from 'swiper';
import { Swiper } from 'swiper/react';
SwiperCore.use([Virtual]);

export function AppSwiper({
    swiperStyle,
    setSwiperRef,
    slidesPerView,
    prevRef,
    nextRef,
    children,
    onSlideChangeTransitionEnd,
    isVirtual,
    ...props
}) {
    return (
        <Swiper
            virtual={isVirtual}
            direction="horizontal"
            allowTouchMove={isMobileOrTablet ? true : false}
            onSwiper={setSwiperRef}
            slidesPerView={slidesPerView}
            observer={true}
            observeParents={true}
            spaceBetween={0}
            speed={isTV ? 0 : 300} // no animation on TV
            centeredSlides={false}
            centerInsufficientSlides={true}
            preloadImages={false}
            simulateTouch={false} // if true, Swiper will accept mouse events like touch events (click and drag to change slides)
            resistance={false} // Set to false if you want to disable resistant bounds
            navigation={{
                prevEl: prevRef.current,
                nextEl: nextRef.current,
            }}
            onSlideChangeTransitionEnd={onSlideChangeTransitionEnd}
            {...props}
        >
            {children}
        </Swiper>
    );
}
