import { isMobile } from 'app/device';
import { GameHeading } from 'components/games';
import { ExpandButton } from 'components/ui/buttons';
import { ScrollView } from 'components/ui/scroll-bar/ScrollView';
import { If } from 'helpers';
import { Fragment } from 'react';
//May need to replace react-dotdotdot with react-truncate https://www.npmjs.com/package/react-truncate
import Dotdotdot from 'react-dotdotdot';
import * as S from './GameDescription.style';
import { splitLines } from 'helpers';

export function GameDescription({ game, minimized, onExpandClicked }) {
    const description = splitLines(game.assets.description).map((e, i) => (
        <Fragment key={i}>
            {e}
            <br />
        </Fragment>
    ));
    return (
        <S.Wrapper>
            <GameHeading game={game} />

            <If condition={minimized}>
                <S.DescriptionWrapperMinimized>
                    <Dotdotdot clamp={isMobile ? 2 : 3} truncationChar="&nbsp;">
                        {description}
                    </Dotdotdot>
                    <S.ExpandWrapper>
                        <ExpandButton
                            disableMoveRight={true}
                            onClick={() => onExpandClicked(game.alias)}
                        />
                    </S.ExpandWrapper>
                </S.DescriptionWrapperMinimized>
            </If>

            <If condition={!minimized}>
                {/* use key to force recreation of scrollview when contents change */}
                <ScrollView key={game.alias}>{description}</ScrollView>
            </If>
        </S.Wrapper>
    );
}
