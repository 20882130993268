import { ConfigFront } from 'services';
import { useLogin } from 'hooks';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useInputDispatcherContext } from 'context';
import { useSelector } from 'react-redux';
import { gameSessionSelector, SESSION_STATE } from 'slices';

export function useHospitalityAd() {
    const { loggedIn } = useLogin();
    const adTimer = useRef(0);
    const [isVisible, setIsVisible] = useState(false);
    const { setInputCallback } = useInputDispatcherContext();
    const { sessionState } = useSelector(gameSessionSelector);

    const stopAdCountdown = useCallback(() => {
        clearInterval(adTimer.current);
    }, []);

    const startAdCountdown = useCallback(() => {
        adTimer.current = setTimeout(() => {
            setIsVisible(true);
        }, ConfigFront.GetAdInfos().interval);
    }, []);

    // only show ad once logged in and requested by backend
    const showAd = loggedIn && ConfigFront.isAdActive();

    useEffect(() => {
        if (!showAd) {
            return;
        }
        // mount or session state change
        // stop countdown, and restart only if no session active
        stopAdCountdown();
        if (sessionState.id === SESSION_STATE.INITIAL.id) {
            startAdCountdown();
        }
    }, [showAd, startAdCountdown, stopAdCountdown, sessionState.id]);

    // set input callback to restart the countdown and/or hide the ad
    useEffect(() => {
        if (!showAd) {
            return;
        }
        setInputCallback(() => {
            stopAdCountdown();
            startAdCountdown();
            // if visible, hide and prevent further processing of the input event
            if (isVisible) {
                setIsVisible(false);
                return true;
            }
            return false;
        });
    }, [
        isVisible,
        showAd,
        stopAdCountdown,
        startAdCountdown,
        setInputCallback,
    ]);

    return {
        url: ConfigFront.GetAdInfos().url,
        isVisible,
    };
}
