// API base url

// APP_BACKEND_URL is defined in the static public/vars.js file so it can be injected
// at deployment time according to each customer, rather than defined at build time
// this can be overridden a dev time using the .env file

// eslint-disable-next-line no-undef
export const backendUrl = APP_BACKEND_URL || process.env.REACT_APP_BACKEND_URL;
const API_BASE_URL = `${backendUrl}/api`;

const ClientOptions = {
    baseURL: API_BASE_URL,
    timeout: 10000,
    ResponseType: 'json',
    validateStatus: null,
};

export default ClientOptions;
