export function IconSettingsGlobal(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            viewBox="0 0 18.427 19.422"
            {...props}
        >
            <defs>
                <clipPath id="a">
                    <path d="M0 0H18.427V19.422H0z" />
                </clipPath>
            </defs>
            <g clipPath="url(#a)">
                <path d="M14.353 0H4.073A4.216 4.216 0 000 4.321a4.216 4.216 0 004.073 4.323h10.28a4.216 4.216 0 004.073-4.322A4.216 4.216 0 0014.353 0M4.6 7.278a2.884 2.884 0 01-2.788-2.957A2.884 2.884 0 014.6 1.365a2.884 2.884 0 012.785 2.956A2.884 2.884 0 014.6 7.278M4.073 19.423h10.281a4.215 4.215 0 004.072-4.322 4.215 4.215 0 00-4.072-4.322H4.073A4.216 4.216 0 000 15.1a4.216 4.216 0 004.073 4.322m9.755-7.279a2.962 2.962 0 010 5.913 2.962 2.962 0 010-5.913" />
            </g>
        </svg>
    );
}
