import { isMobile } from 'app/device';
import { PlayButton, TrailerButton } from 'components/games';
import { DangerButton, NeutralButton, RegularButton } from 'components/ui';
import { useSpatialNavContext } from 'context';
import { useMultiViewContext } from 'context/multi-view';
import { If } from 'helpers';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './ActionButtons.style';

export function ActionButtons({ onClose, game, onUnFavorite, navRef }) {
    const { t } = useTranslation();
    const { setView } = useMultiViewContext();
    const [confirm, setConfirm] = useState(false);
    const playButtonRef = useRef();
    const { refreshTree, setFocus } = useSpatialNavContext();

    const unFav = () => {
        onClose();
        onUnFavorite(game.alias);
    };

    useEffect(() => {
        // refresh nav tree on rerender, in case of switch between 3- and 4-button configurations
        refreshTree({});
        // if the element with focus has disappeared (switch from 4 to 3 while focus was on quickmatch),
        // the focus goes back to body. In that case force on play button
        if (document.activeElement === document.body) {
            if (playButtonRef.current) setFocus(playButtonRef.current);
        }
    });

    // override move right on mobile to avoid focusing the favorite icon in description view
    const mobileFocusNav = () => {
        if (!isMobile || !navRef?.current) {
            // no override
            return true;
        }
        setFocus(navRef.current);
        return false;
    };

    return (
        <S.Wrapper hasQuickMatch={game.hasQuickMatch}>
            <PlayButton
                defaultElement
                game={game}
                playButtonRef={playButtonRef}
                overrideMoveRight={mobileFocusNav}
            />
            <If condition={!onUnFavorite}>
                <TrailerButton game={game} overrideMoveRight={mobileFocusNav} />
            </If>
            <If condition={onUnFavorite}>
                <If condition={!confirm}>
                    <NeutralButton
                        title={t('buttons.unfavorite')}
                        onClick={() => setConfirm(true)}
                        overrideMoveRight={mobileFocusNav}
                    >
                        {t('buttons.unfavorite')}
                    </NeutralButton>
                </If>
                <If condition={confirm}>
                    <DangerButton
                        focusOnMount
                        title={t('buttons.confirm')}
                        onBlur={() => setConfirm(false)}
                        onClick={unFav}
                        overrideMoveRight={mobileFocusNav}
                    >
                        {t('buttons.confirm')}
                    </DangerButton>
                </If>
            </If>
            <If condition={game.hasQuickMatch}>
                <RegularButton
                    onClick={() => setView('quick-match')}
                    overrideMoveRight={mobileFocusNav}
                >
                    {t('buttons.quick-match')}
                </RegularButton>
            </If>
            <RegularButton
                onClick={() => setView('more-view')}
                overrideMoveRight={mobileFocusNav}
            >
                {t('buttons.more')}
            </RegularButton>
        </S.Wrapper>
    );
}
