import { isMobile, isTV } from 'app/device';
import { IconArrowLeft, IconArrowRight } from 'components/ui';
import { SpatialNavSection, useSpatialNavContext } from 'context';
import { If } from 'helpers';
import { useBackPress } from 'hooks';
import { forwardRef, useEffect, useRef } from 'react';
import * as S from './NavButtons.style';

export const NavButtons = forwardRef(
    ({ onClose, onNext, onPrev, index, indexMax }, forwardedRef) => {
        const { setEnabled, setFocus } = useSpatialNavContext();
        const prevRef = useRef();
        const nextRef = useRef();

        useBackPress(onClose);

        const prevDisabled = index === 0;
        const nextDisabled = index === indexMax;

        useEffect(() => {
            setEnabled(prevRef.current, !prevDisabled);
            setEnabled(nextRef.current, !nextDisabled);
            if (document.activeElement === prevRef.current && prevDisabled) {
                setFocus(nextRef.current);
            } else if (
                document.activeElement === nextRef.current &&
                nextDisabled
            ) {
                setFocus(prevRef.current);
            }
        }, [nextDisabled, prevDisabled, setEnabled, setFocus]);

        return (
            <SpatialNavSection ref={forwardedRef} enterTo="default-element">
                <S.Wrapper>
                    <If condition={!isTV}>
                        <S.Icon
                            defaultElement={nextDisabled && prevDisabled}
                            onClick={onClose}
                            disableMoveRight={true} // do not move out of window (to settings)
                            disableMoveUp={true} // do not move out of window (to menu bar)
                        >
                            <S.IconClose />
                        </S.Icon>
                    </If>

                    <S.Icon
                        ref={prevRef}
                        defaultElement={nextDisabled && !prevDisabled}
                        onClick={onPrev}
                        disableMoveRight={true} // do not move out of window (to settings)
                        disableMoveUp={isTV} // do not move out of window (to menu bar)
                        disableMoveDown={isMobile && nextDisabled} // do not move to action buttons (on mobile)
                        style={
                            prevDisabled && {
                                opacity: '0.35',
                                filter: 'saturate(0)',
                                pointerEvents: 'none',
                            }
                        }
                    >
                        <IconArrowLeft fill="white" />
                    </S.Icon>

                    <S.Icon
                        ref={nextRef}
                        defaultElement={!nextDisabled}
                        onClick={onNext}
                        disableMoveRight={true} // do not move out of window (to settings)
                        disableMoveDown={isMobile} // do not move to action buttons (on mobile)
                        style={
                            nextDisabled && {
                                opacity: '0.35',
                                filter: 'saturate(0)',
                                pointerEvents: 'none',
                            }
                        }
                    >
                        <IconArrowRight fill="white" />
                    </S.Icon>
                </S.Wrapper>
            </SpatialNavSection>
        );
    }
);
