import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useSelector } from 'react-redux';
import { gameSessionSelector, SESSION_STATE } from 'slices';
import uniqid from 'uniqid';
import KeyboardListener from './KeyboardListener';
import GamepadListener from './GamepadListener';

const Context = React.createContext(null);

export const useInputDispatcherContext = () => useContext(Context);

const layer = () => ({
    back: {},
    select: {},
    up: {},
    down: {},
    left: {},
    right: {},
    up2: {},
    down2: {},
    left2: {},
    right2: {},
    triggerBottomLeft: {},
    triggerBottomRight: {},
    actionTop: {},
    home: {},
});

const inputDevices = [new KeyboardListener(), new GamepadListener()];

export function InputDispatcherProvider({ children }) {
    const layers = useRef([]);
    const [layersCount, setLayersCount] = useState(1);
    const inputCallbackRef = useRef();
    const sessionStateRef = useRef();
    const { sessionState } = useSelector(gameSessionSelector);

    // the input call back is called on every input, before all callbacks
    // for the current layer and input are called
    // the callback may return true to prevent the layer callbacks from
    // running
    const setInputCallback = useCallback((func) => {
        inputCallbackRef.current = func;
    }, []);

    // Get number of Gamepads
    const getNumberOfGamepads = useCallback(() => inputDevices[1].Count(), []);

    // Get current layer
    const currentLayer = useCallback(
        () => layers.current[layers.current.length - 1],
        []
    );

    // Called on input events
    const dispatchCallback = useCallback(
        (button) => {
            // call main callback if specified and do not run the layer
            // callbacks if it returns true
            if (inputCallbackRef.current?.()) {
                return;
            }
            // Evaluate all callbacks of the target button
            Object.values(currentLayer()[button]).forEach((callback) =>
                callback()
            );
        },
        [currentLayer]
    );

    // Register a button callback and returns its layer and unique id
    const registerCallback = useCallback(
        (targetBtn, callback) => {
            let callbackId = uniqid();
            currentLayer()[targetBtn][callbackId] = callback;
            return [layers.current.length - 1, callbackId];
        },
        [currentLayer]
    );

    // unregister a button callback
    const unregisterCallback = useCallback(
        (targetBtn, layer, callbackId) => {
            // remove callback if its containing layer has not been destroyed yet
            if (layer < layers.current.length) {
                delete layers.current[layer][targetBtn][callbackId];
            }
        },
        [layers]
    );

    // Pause input dispatch
    const pauseInputDispatch = useCallback(() => {
        inputDevices.forEach((inputDevice) => inputDevice.Stop());
    }, []);

    // Resume input dispatch
    // Test if we should resume input dispatcher based on session state, if game session
    // is running we don't want the user being able to navigate on the UI or accidentally
    // close the session with a backpress
    // The session state id is kept inside a ref to avoid capturing the session state
    // inside the callback, which would break some use cases (e.g. cleanup effect of
    // unmount of GameSession)
    sessionStateRef.current = sessionState.id;
    const resumeInputDispatch = useCallback(() => {
        if (sessionStateRef.current !== SESSION_STATE.INITIAL.id) {
            return;
        }
        inputDevices.forEach((inputDevice) =>
            inputDevice.Start(dispatchCallback)
        );
    }, [dispatchCallback]);

    // Push new layer
    const pushLayer = useCallback(() => {
        layers.current.push(layer());
        setLayersCount(layers.current.length);
    }, []);

    // Pop layer
    const popLayer = useCallback(() => {
        if (layers.current.length > 1) {
            layers.current.pop();
            setLayersCount(layers.current.length);
        }
    }, []);

    // Start capture on mount
    useEffect(() => {
        inputDevices.map((inputDevice) => inputDevice.Start(dispatchCallback));
    }, [dispatchCallback]);

    useEffect(() => {
        // Pause input dispatcher when current tab / window is not active | prevent unintentional gamepad inputs while not on the app
        window.addEventListener('blur', pauseInputDispatch);
        window.addEventListener('focus', resumeInputDispatch);

        return () => {
            window.removeEventListener('blur', pauseInputDispatch);
            window.removeEventListener('focus', resumeInputDispatch);
        };
    }, [resumeInputDispatch, pauseInputDispatch]);

    return (
        <Context.Provider
            value={{
                setInputCallback,
                registerCallback,
                unregisterCallback,
                pauseInputDispatch,
                resumeInputDispatch,
                pushLayer,
                popLayer,
                layersCount,
                getNumberOfGamepads,
            }}
        >
            {children}
        </Context.Provider>
    );
}
