import styled from 'styled-components';

export const MessageWrapper = styled.div`
    width: 100%;
    margin-bottom: 2rem;
`;

export const Text = styled.div`
    margin-bottom: 20px;
    width: 100%;
    text-align: left;
`;

export const LangSelectionWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: -30px;
    min-width: ${(props) => (props.nbLanguages < 3 ? '500px' : '')};
`;

export const LangWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-top: 30px;
    width: calc(100% / 3);
`;

export const Lang = styled.div`
    margin-left: 1rem;
`;
