export function IconMultiplayer(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            viewBox="0 0 37.094 37.296"
            {...props}
        >
            <path d="M28.958 19.646H8.137A8.136 8.136 0 000 27.782v1.376a8.137 8.137 0 008.137 8.137h20.821a8.136 8.136 0 008.136-8.137v-1.376a8.136 8.136 0 00-8.136-8.136zm.176 3.655a1.752 1.752 0 11-1.754 1.752 1.752 1.752 0 011.754-1.753zM11.618 30.37h-1.526v1.525a1.752 1.752 0 11-3.5 0V30.37H5.066a1.752 1.752 0 010-3.5h1.526v-1.53a1.752 1.752 0 113.5 0v1.526h1.526a1.752 1.752 0 010 3.5zm14.067-.116a1.753 1.753 0 111.754-1.754 1.753 1.753 0 01-1.753 1.754zm3.448 3.447a1.753 1.753 0 111.752-1.754 1.753 1.753 0 01-1.751 1.753zm3.447-3.447a1.753 1.753 0 111.752-1.754 1.755 1.755 0 01-1.751 1.754zM9.166 16.5H3.261a1.136 1.136 0 01-1.169-.972 2.257 2.257 0 01.018-.553 6.6 6.6 0 012.127-4.208A6.461 6.461 0 017.91 9.033a8.389 8.389 0 013.037.083 6.669 6.669 0 015.151 5.118 6.08 6.08 0 01.153 1.131 1.112 1.112 0 01-1.041 1.133c-.127.01-.255.005-.382.005zm9.433-.019a7.994 7.994 0 00-2.41-7.008 2.986 2.986 0 01.292-.139 7.512 7.512 0 012.384-.376 6.685 6.685 0 016.519 4.793 9.631 9.631 0 01.271 1.459 1.124 1.124 0 01-1.162 1.289c-1.052.01-2.105 0-3.157 0h-2.359c-.124.001-.25-.016-.378-.021zm9.355.019l.12-.571a5.224 5.224 0 00-.121-2.2 7.988 7.988 0 00-2.151-4.005c-.07-.072-.13-.153-.237-.283.272-.094.491-.19.721-.244a7.216 7.216 0 014.913.382 6.527 6.527 0 013.723 4.715 5.741 5.741 0 01.131 1.027 1.105 1.105 0 01-1.142 1.172c-.324.008-.648 0-.972 0h-4.984zM18.596.014a3.9 3.9 0 013.743 2.62 3.719 3.719 0 01-1.6 4.306 4.06 4.06 0 01-5.188-.725 3.647 3.647 0 01.019-4.9A3.906 3.906 0 0118.597.011zm-5.489 4.131a3.612 3.612 0 01-2.321 3.08 4.066 4.066 0 01-4.935-1.39A3.689 3.689 0 016.863.698a4.126 4.126 0 015.242.542 3.881 3.881 0 011.003 2.902zM27.992 0a3.887 3.887 0 013.468 1.981 3.717 3.717 0 01-.463 4.229 4.089 4.089 0 01-5.692.353 3.688 3.688 0 01.11-5.68A3.888 3.888 0 0127.992 0z" />
        </svg>
    );
}
