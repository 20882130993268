import { useNaclClient } from 'hooks';
import * as S from './NaclClient.style';
import ReactDOM from 'react-dom';
import { Authentication } from 'services';
import { If } from 'helpers';
import { useEffect } from 'react';
import { useRef } from 'react';

export function NaclClient() {
    const { ref, logRef } = useNaclClient();
    const logData = useRef();

    // use specific variants of client + optional logger console according to specific users
    const username = Authentication.GetUsername();
    // for all our QA users - latest variant and no console
    let showConsole = false;
    let manifestSource = 'tizen/qa_Knuckles.nmf';
    if (username === 'Regis_BT') {
        // Bouygues test user - specific version and console
        showConsole = true;
        manifestSource = 'tizen/stats_Knuckles.nmf';
    } else if (username.startsWith('samsung_') || username === 'sandra') {
        // Samsung users - current production client and no console
        showConsole = false;
        manifestSource = 'tizen/Knuckles.nmf';
    }

    useEffect(() => {
        // autoscroll console when new text is added
        const element = logRef.current;
        if (element) {
            setInterval(() => {
                const text = element.textContent;
                if (text !== logData.current) {
                    element.scrollTop = element.scrollHeight;
                    logData.current = text;
                }
            }, 200);
        }
    }, [logRef]);

    return ReactDOM.createPortal(
        <S.Wrapper>
            <embed
                ref={ref}
                type="application/x-nacl"
                src={manifestSource}
                showConsole={showConsole}
            />
            <If condition={showConsole}>
                <div ref={logRef} />
            </If>
        </S.Wrapper>,
        document.body
    );
}
