import { resetGlobalNavigation, setSettingsVisible } from 'slices';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import history from 'app/history';
import Routes from 'app/routes';
import { useSpatialNavContext } from 'context';

export function useAsideSettings(settingsButtonRef) {
    const wrapperRef = useRef();
    const dispatch = useDispatch();
    const { resetLastFocused } = useSpatialNavContext();

    useEffect(() => {
        const onDocumentMouseDown = (e) => {
            if (
                e.target !== wrapperRef.current &&
                e.target !== settingsButtonRef.current
            )
                if (
                    !wrapperRef.current?.contains(e.target) &&
                    !settingsButtonRef.current?.contains(e.target)
                )
                    dispatch(setSettingsVisible(false));
        };

        document.addEventListener('mousedown', onDocumentMouseDown);
        return () =>
            document.removeEventListener('mousedown', onDocumentMouseDown);
    }, [dispatch, settingsButtonRef]);

    const onChangeProfile = () => {
        history.push(Routes.PROFILE_SELECTION);
        // hide the nav bar
        dispatch(resetGlobalNavigation());
        // reset last focus in nav bar - only once hidden
        resetLastFocused();
    };

    return { wrapperRef, onChangeProfile };
}
