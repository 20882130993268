import { forwardRef, useCallback } from 'react';
import * as S from './GameWindow.style';
import { MultiView, View } from 'context/multi-view';
import { DescriptionView } from './views/description/DescriptionView';
import { QuickMatchView } from './views/quickmatch/QuickMatchView';
import { MoreView } from './views/more/MoreView';
import { useActionTopPress, useHomePress } from 'hooks';
import { useToggleFavorite } from 'hooks/games/useToggleFavorite';
import { isHP } from 'app/device';
import { Games } from 'services';

export const GameWindow = forwardRef(
    ({ defaultView, ...props }, forwardRef) => {
        const favorite = useToggleFavorite();

        useActionTopPress(() => {
            if (!Games.FavoritesEnabled()) {
                return;
            }
            favorite.toggleGame(props.game.alias);
        });

        const { visible, onClose } = props;
        useHomePress(
            useCallback(() => {
                if (visible && isHP) {
                    onClose();
                }
            }, [visible, onClose])
        );

        return (
            <S.Wrapper>
                <S.Window ref={forwardRef}>
                    <MultiView
                        defaultView={
                            defaultView.current.length
                                ? defaultView.current
                                : 'description'
                        }
                    >
                        <View
                            name="description"
                            component={DescriptionView}
                            {...props}
                        />
                        <View
                            name="quick-match"
                            component={QuickMatchView}
                            {...props}
                        />
                        <View
                            name="more-view"
                            component={MoreView}
                            {...props}
                        />
                    </MultiView>
                </S.Window>
            </S.Wrapper>
        );
    }
);
