import { maxCols } from 'helpers';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Games } from 'services';
import { favoritesSelector, profileSelector, setFavorite } from 'slices';

export function useFavorites() {
    const { favorites } = useSelector(favoritesSelector);
    const { currentProfile } = useSelector(profileSelector);
    const dispatch = useDispatch();

    // Make games list
    const [columns, mainFavorite, games] = useMemo(() => {
        // Lookup table to speed up things
        let lookupTable = {};
        favorites.forEach((e) => {
            lookupTable[e] = true;
        });
        // alphabetical sorting on localized title
        const games = Games.GetGames()
            .filter((game) => lookupTable[game.alias])
            .sort((a, b) => a.assets.title.localeCompare(b.assets.title));

        // main favorite is the first o1ne
        const mainFavorite = games[0];

        // Init columns
        const columns = [...Array(maxCols)].map(() => []);

        // Fill columns with rest of favorites
        games.slice(1).forEach((game, index) => {
            columns[index % maxCols].push(game);
        });
        return [columns, mainFavorite, games];
    }, [favorites]);

    // UnFav game
    const onUnFavorite = (gameAlias) => {
        dispatch(
            setFavorite({
                profileUID: currentProfile.uid,
                gameAlias,
                favorite: false,
            })
        );
    };

    return {
        mainFavorite,
        games,
        columns,
        onUnFavorite,
    };
}
