import * as S from './LanguageBox.style';
import { PrimaryButton } from '../../buttons';
import { useTranslation } from 'react-i18next';
import { RowLayout } from '../../layout';
import { LangIcon } from '../../icons';
import React from 'react';
import { MessageBoxWrapper } from '../message-box-wrapper/MessageBoxWrapper';
import Config from 'services/ConfigFront';
import { MessageBoxTitle } from 'components/ui/message-box';
import ReactDOM from 'react-dom';
import { useBackPress } from 'hooks';
import { SpatialNavSection } from 'context';

export function LanguageBox({ onClose, lang, changeLanguage }) {
    const { t } = useTranslation();
    const languages = Config.GetSupportedLanguages();

    useBackPress(onClose);

    const onLanguageChanged = (lang) => {
        changeLanguage(lang);

        // timeout to prevent lang flickering
        setTimeout(() => {
            onClose();
        }, 100);
    };

    return ReactDOM.createPortal(
        <MessageBoxWrapper>
            <MessageBoxTitle title={t('languages.change')} />
            <S.MessageWrapper>
                <S.Text>{t('languages.select')}</S.Text>
                <SpatialNavSection enterTo="last-focused">
                    <S.LangSelectionWrapper nbLanguages={languages.length}>
                        {languages.map((language) => {
                            return (
                                <S.LangWrapper
                                    key={language}
                                    onClick={() => onLanguageChanged(language)}
                                >
                                    <LangIcon
                                        defaultElement={language === lang}
                                        lang={language}
                                    />
                                    <S.Lang>
                                        {/* ugly wart because backend uses zt for zh-tw */}
                                        {t(
                                            `languages.${
                                                language === 'zt'
                                                    ? 'zh'
                                                    : language
                                            }`
                                        )}
                                    </S.Lang>
                                </S.LangWrapper>
                            );
                        })}
                    </S.LangSelectionWrapper>
                </SpatialNavSection>
            </S.MessageWrapper>
            <RowLayout>
                <PrimaryButton onClick={onClose}>
                    {t('buttons.close')}
                </PrimaryButton>
            </RowLayout>
        </MessageBoxWrapper>,
        document.body
    );
}
