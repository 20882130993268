import { NavButton } from 'components/profile';
import { Profile } from 'components/profile/selection-manager/profile/Profile';
import { IconArrowLeft, IconArrowRight } from 'components/ui';
import { AppSwiper } from 'components/ui/swiper/AppSwiper';
import { SpatialNavSection } from 'context';
import { If } from 'helpers';
import { useProfileListSwiper } from './useProfileListSwiper';
import { SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import * as S from './ProfileListSwiper.style';

const swiperBreakpoints = {
    // when window width is >= 960px
    960: {
        slidesPerView: 4,
    },
};

const slidesPerView = 3;

export function ProfileListSwiper({ list, listItemSelected }) {
    const {
        prevSlide,
        nextSlide,
        swiperRef,
        setSwiperRef,
        prevRef,
        nextRef,
        onSlideChangeTransitionEnd,
        slideRefs,
        activeIndex,
        focusActiveProfile,
    } = useProfileListSwiper(list.length);

    return (
        <SpatialNavSection focusOnMount enterTo="last-focused">
            <S.Wrapper>
                <If condition={swiperRef?.params.slidesPerView < list.length}>
                    <NavButton
                        onClick={prevSlide}
                        isDisabled={activeIndex === 0}
                    >
                        <IconArrowLeft />
                    </NavButton>
                </If>
                <AppSwiper
                    style={S.ProfileListSwiperStyle}
                    setSwiperRef={setSwiperRef}
                    slidesPerView={slidesPerView}
                    prevRef={prevRef}
                    nextRef={nextRef}
                    onSlideChangeTransitionEnd={onSlideChangeTransitionEnd}
                    isVirtual={false}
                    spaceBetween={0}
                    breakpoints={swiperBreakpoints}
                >
                    {list.map((profile, index) => (
                        <SwiperSlide key={profile.uid} virtualIndex={index}>
                            <Profile
                                ref={slideRefs[index]}
                                data={profile}
                                count={slidesPerView}
                                onClick={() => listItemSelected(profile)}
                                overrideMoveLeft={prevSlide}
                                overrideMoveRight={nextSlide}
                                onFocus={focusActiveProfile}
                            />
                        </SwiperSlide>
                    ))}
                </AppSwiper>
                <If condition={swiperRef?.params.slidesPerView < list.length}>
                    <NavButton
                        onClick={nextSlide}
                        isDisabled={activeIndex >= list.length - 1}
                    >
                        <IconArrowRight />
                    </NavButton>
                </If>
            </S.Wrapper>
        </SpatialNavSection>
    );
}
