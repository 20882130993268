import { isHP } from 'app/device';
import {
    ConfirmationBox,
    IconHeart,
    IconSettingsGlobal,
    LanguageBox,
} from 'components/ui';
import { SupportMessageBox } from 'components/ui/message-box/support-message-box/SupportMessageBox';
import { SpatialNavSection, useSpatialNavContext } from 'context';
import { If } from 'helpers';
import { useAsideNav, useFavorites } from 'hooks';
import { useTranslation } from 'react-i18next';
import { Games } from 'services';
import * as S from './AsideNav.style';
import { Settings } from './settings/Settings';

export function AsideNav({ menuWrapperRef }) {
    const { t } = useTranslation();
    const asideNav = useAsideNav();
    const { games } = useFavorites();
    const { setFocus } = useSpatialNavContext();

    return (
        <>
            {/*SHOW LOGOUT*/}
            <If condition={asideNav.logout.visible}>
                <ConfirmationBox
                    message={t('login.logout_confirmation')}
                    onAccept={asideNav.doLogout}
                    onDecline={() => {
                        asideNav.logout.callbacks.declineLogout();
                        asideNav.settingsButtonRef.current.focus();
                    }}
                />
            </If>

            {/*SHOW QUIT*/}
            <If condition={asideNav.quit.visible}>
                <ConfirmationBox
                    message={t('settings.quit_confirmation')}
                    onAccept={asideNav.quit.callbacks.doQuit}
                    onDecline={() => {
                        asideNav.quit.callbacks.decline();
                        asideNav.settingsButtonRef.current.focus();
                    }}
                />
            </If>

            {/*SHOW SUPPORT*/}
            <If condition={asideNav.support.visible}>
                <SupportMessageBox
                    onClose={asideNav.support.hideSupport}
                    supportMethods={asideNav.support.supportMethods}
                />
            </If>

            {/*SHOW LANGUAGE BOX*/}
            <If condition={asideNav.language.visible}>
                <LanguageBox
                    lang={asideNav.language.lang}
                    changeLanguage={asideNav.language.changeLanguage}
                    onClose={asideNav.language.hideLanguageBox}
                />
            </If>

            {/*SHOW SETTINGS*/}
            <If condition={asideNav.settingsVisible}>
                <Settings
                    settingsButtonRef={asideNav.settingsButtonRef}
                    onLogout={asideNav.logout.callbacks.showLogoutBox}
                    onQuit={asideNav.quit.callbacks.show}
                    onShowSupport={asideNav.support.showSupport}
                    onShowLanguageBox={asideNav.language.showLanguageBox}
                    lang={asideNav.language.lang}
                />
            </If>

            <SpatialNavSection>
                <S.Wrapper
                    favoritesVisible={asideNav.favoritesVisible}
                    settingsVisible={asideNav.settingsVisible}
                    settingsState={asideNav.settingsHover}
                >
                    <If condition={Games.FavoritesEnabled()}>
                        <S.FavoriteIcon
                            key={`favorites${games.length}`}
                            checked={games.length > 0}
                            onFocus={asideNav.onFavoritesFocus}
                            onBlur={asideNav.onFavoritesBlur}
                        >
                            <IconHeart />
                        </S.FavoriteIcon>
                    </If>
                    <S.SettingsIcon
                        ref={asideNav.settingsButtonRef}
                        onClick={asideNav.onSettingsClick}
                        onFocus={asideNav.onSettingsFocus}
                        onMouseEnter={asideNav.toggleSettingsState}
                        onMouseLeave={asideNav.toggleSettingsState}
                        overrideMoveLeft={() => {
                            if (isHP) {
                                // focus issue when favorite btn not here showing
                                asideNav.hideSettings();
                                setFocus(menuWrapperRef.current);
                                return false;
                            }
                            return true;
                        }}
                    >
                        <IconSettingsGlobal />
                    </S.SettingsIcon>
                </S.Wrapper>
            </SpatialNavSection>
        </>
    );
}
