import { isHP } from 'app/device';
import Routes from 'app/routes';
import { useBackPress } from 'hooks';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    doPassCheck,
    passwordCheckSelector,
    showGlobalNavigation,
    updatePassword,
} from 'slices';

export function usePasswordChecker() {
    const { register, handleSubmit } = useForm();
    const history = useHistory();
    const dispatch = useDispatch();
    const { routeFrom, routeTo } = useSelector(passwordCheckSelector);
    const { passCheckError, checkPassed, isChecking } = useSelector(
        passwordCheckSelector
    );

    useEffect(() => {
        // Password OK, route to target
        if (checkPassed) {
            if (routeTo === 'quit' && isHP) {
                window.close();
            } else {
                history.push(routeTo);
            }
        }
    }, [checkPassed, history, routeTo]);

    const onCancel = () => {
        // if not on profile selection screen show nav
        if (routeFrom !== Routes.PROFILE_SELECTION)
            dispatch(showGlobalNavigation());

        // Go back
        history.push(routeFrom);
    };

    // Back button
    useBackPress(onCancel);

    const onPasswordChange = (e) => dispatch(updatePassword(e.target.value));

    const onSubmit = handleSubmit((data) => {
        dispatch(doPassCheck(data.password));
    });

    return {
        register,
        onSubmit,
        onCancel,
        onPasswordChange,
        passCheckError,
        isChecking,
    };
}
