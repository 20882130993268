import { useTriggerBottomLeftPress, useTriggerBottomRightPress } from 'hooks';
import { createRef, useCallback, useRef, useState } from 'react';

// this tracks which slides are actually visible and provides custom
// prevSlide and nextSlide functions that will only trigger a swipe
// effect when necessary
// the "current" slide is indicated by activeIndex, which is updated
// at the end of the change - i.e. after the transition ends, or immediately
// when there is no transition
// you may also specify transitionEndCallback to get called at the end of
// the transitions
export function useSwiper(nbSlides, transitionEndCallback) {
    const [swiperRef, setSwiperRef] = useState();
    const [activeIndex, setActiveIndex] = useState(0);
    const [firstVisibleIndex, setFirstVisibleIndex] = useState(0);
    const [slideRefs] = useState(() =>
        Array.from({ length: nbSlides }, () => createRef())
    );
    const prevRef = useRef(null);
    const nextRef = useRef(null);
    // for some reason, swiperRef.activeIndex may be incorrect at the end of
    // the transition, so we keep the expected index in a ref instead
    const nextActiveIndex = useRef(0);

    // prev and next handlers
    // swiperRef may be undefined if we are not actually showing the swiper, in that
    // case just change the activeIndex
    // these return false so they can be used as override callbacks for focusable items
    const prevSlide = useCallback(() => {
        if (activeIndex !== 0) {
            const prevIndex = activeIndex - 1;
            if (swiperRef && activeIndex === firstVisibleIndex) {
                // set the next index and swipe
                nextActiveIndex.current = prevIndex;
                swiperRef.slidePrev();
                setFirstVisibleIndex(prevIndex);
            } else {
                // no transition, change active index now
                setActiveIndex(prevIndex);
            }
        }
        return false;
    }, [activeIndex, firstVisibleIndex, swiperRef]);

    const nextSlide = useCallback(() => {
        if (activeIndex !== nbSlides - 1) {
            const nextIndex = activeIndex + 1;
            if (
                swiperRef &&
                activeIndex ===
                    firstVisibleIndex + swiperRef.params.slidesPerView - 1
            ) {
                // set the next index and swipe
                nextActiveIndex.current = nextIndex;
                swiperRef.slideNext();
                setFirstVisibleIndex(
                    nextIndex - swiperRef.params.slidesPerView + 1
                );
            } else {
                // no transition, change active index now
                setActiveIndex(activeIndex + 1);
            }
        }
        return false;
    }, [activeIndex, firstVisibleIndex, nbSlides, swiperRef]);

    const onSlideChangeTransitionEnd = useCallback(
        (...params) => {
            // change active index after transition
            setActiveIndex(nextActiveIndex.current);
            transitionEndCallback?.(...params);
        },
        [transitionEndCallback]
    );

    useTriggerBottomLeftPress(prevSlide);
    useTriggerBottomRightPress(nextSlide);

    return {
        prevSlide,
        nextSlide,
        prevRef,
        nextRef,
        slideRefs,
        onSlideChangeTransitionEnd,
        swiperRef,
        setSwiperRef,
        activeIndex,
    };
}
