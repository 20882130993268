import { SharedElement } from 'components';
import { Instruction } from 'components/profile';
import { ProfileManager, Wrapper } from 'components/profile/selection-manager';
import { ProfileList } from 'components/profile/selection-manager/profile-list';
import {
    BlurBox,
    IconButton,
    IconPlus,
    NeutralButton,
    RowLayout,
} from 'components/ui';
import { SpatialNavSection } from 'context';
import { useProfileManager } from 'hooks';
import { useTranslation } from 'react-i18next';

export function ProfileManagerScreen() {
    const { t } = useTranslation();
    const { profiles, onProfileSelected, onBack, onAddProfile } =
        useProfileManager();

    return (
        <RowLayout alignItems="center" justifyContent="center" fullSize>
            <SharedElement id="box">
                <BlurBox>
                    <Wrapper count={profiles.length}>
                        <ProfileManager />
                        <Instruction>{t('profile.manage_profile')}</Instruction>
                        <RowLayout fullWidth>
                            <ProfileList
                                list={profiles}
                                listItemSelected={onProfileSelected}
                            />
                        </RowLayout>
                        <SpatialNavSection>
                            <RowLayout>
                                <NeutralButton
                                    onClick={onBack}
                                    style={{
                                        marginRight: '1rem',
                                        minWidth: '230px',
                                    }}
                                >
                                    {t('buttons.back')}
                                </NeutralButton>
                                <IconButton
                                    icon={IconPlus}
                                    button={NeutralButton}
                                    onClick={onAddProfile}
                                    style={{
                                        minWidth: '230px',
                                    }}
                                >
                                    {t('buttons.create')}
                                </IconButton>
                            </RowLayout>
                        </SpatialNavSection>
                    </Wrapper>
                </BlurBox>
            </SharedElement>
        </RowLayout>
    );
}
